import React, { createContext, useState, useEffect } from 'react';
import publicAxios from '../utils/api';

export const SiteSettingContext = createContext();

export const SiteSettingProvider = ({ children }) => {
    const [siteSettings, setSiteSettings] = useState([]);

    const fetchSiteSetting = async () => {
        try {
            const { data } = await publicAxios.get(`public-settings`);
            setSiteSettings(data?.data || []);
        } catch (error) {
            console.error('Error fetching site settings:', error);
        }
    };
    // Fetch blogs and categories from the API
    useEffect(() => {
        fetchSiteSetting();
    }, []);

    return (
        <SiteSettingContext.Provider
            value={{
                siteSettings,
            }}
        >
            {children}
        </SiteSettingContext.Provider>
    );
};
