import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { userAxios } from '../utils/api';
import { logger } from '../utils/SiteHelpers';
import { config } from '../config/config';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ element, ...rest }) => {
    const { user, accessToken, userId } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const checkSubscriptionStatus = async () => {
            if (!user) {
                logger.log('User not authenticated');
                return; // Exit if there is no user
            }

            try {
                if (!accessToken) {
                    throw new Error('No access token available.');
                }

                const apiUrl = `${config.subscriptionUrl}access`;
                const payload = { user_id: userId };
                const axiosConfig = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                };

                const response = await userAxios.post(
                    apiUrl,
                    payload,
                    axiosConfig,
                );

                // Check if the user is not paid and redirect to plan page
                if (response.data.isPaid === 0) {
                    return navigate('/settings/billing/plans');
                }
            } catch (error) {
                logger.error('Error verifying subscription:', error);
            }
        };

        checkSubscriptionStatus();
    }, [user]);

    return element; // Render the protected element if user is paid
};

export default ProtectedRoute;
