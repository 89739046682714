import { Box } from '@mui/material';
import { ListItems } from '../../tools/improver/schemas';
import { ExternalLink } from '../../../../utils/SiteHelpers';
import { checkAndSaveIOSSource } from '../../../../utils/Utils';

export const isIosSource = checkAndSaveIOSSource();
const agendaDeatils = [
    {
        name: 'Arbitration & Mediation',
    },
    {
        name: ' Child Welfare',
    },
    {
        name: 'Domestic Violence',
    },
    {
        name: 'Drugs & Paternity Testing',
    },
    {
        name: 'Family Law',
    },
    {
        name: 'Human Trafficking',
    },
    {
        name: 'Immigration',
    },
    {
        name: 'Modern Legal Technology',
    },
];

const taxasList = [
    {
        name: '2025 Texas Bar Association Approval (PDF)',
        url: '2025-toolkitlaw-cle-webinar-approval-174262489.pdf',
    },
    {
        name: '2024 Texas Bar Association Approval (PDF) ',
        url: '2024-toolkitlaw-cle-webinar-approval.pdf',
    },
    {
        name: '2023 Texas Bar Association Approval (PDF) ',
        url: '2023-toolkitlaw-cle-webinar-approval.pdf',
    },
];

const cleLinks = [
    { name: 'Alaska', url: 'https://alaskabar.org/cle-mcle/mcle-faqs/' },
    {
        name: 'California',
        url: 'https://www.calbar.ca.gov/attorneys/mcle-cle/requirements/approved-jurisdictions',
    },
    {
        name: 'Connecticut',
        url: 'https://jud.ct.gov/Publications/PracticeBook/PB_2018.pdf#page=132',
    },
    { name: 'Maine', url: 'https://www.mainebar.org/page/CLE' },
    {
        name: 'New Jersey',
        url: 'https://njcourts.gov/attorneys/attclefaq.html',
    },
    {
        name: 'New York',
        url: 'https://ww2.nycourts.gov/attorneys/cle/approvedjurisdictions.shtml',
    },
    { name: 'North Dakota', url: 'https://www.sband.org/page/cle_policies' },
    {
        name: 'Washington, D.C.',
        url: 'https://www.dcbar.org/for-lawyers/continuing-legal-education/mcle-credit/credit-in-mcle-states',
    },
];

const clePages = [
    { name: 'Access CLE', url: 'https://www.toolkit.law/webinars/access-cle' },
    { name: 'Agenda', url: 'https://www.toolkit.law/webinars/agenda' },
    {
        name: 'CLE Cruise each February',
        url: 'https://www.toolkit.law/webinars/cle-cruise',
    },
    {
        name: 'CLE Resources',
        url: 'https://www.toolkit.law/webinars/cle-resources',
    },
    { name: 'Contact Us', url: 'https://www.toolkit.law/webinars/contact-us' },
    {
        name: 'FAQs regarding our CLE',
        url: 'https://www.toolkit.law/webinars/faqs',
    },
    {
        name: 'Presenter Instructions',
        url: 'https://www.toolkit.law/webinars/presenters',
    },
    {
        name: 'Helpsheet Materials',
        url: 'https://www.toolkit.law/webinars/helpsheets',
    },
    {
        name: 'Jurisdictions',
        url: 'https://www.toolkit.law/webinars/jurisdictions',
    },
    {
        name: 'Reporting Your CLE',
        url: 'https://www.toolkit.law/webinars/reporting',
    },
    {
        name: 'Share our CLE with your colleagues and jurisdiction',
        url: 'https://www.toolkit.law/webinars/share-cle',
    },
];

export const basicInformationDetails = [
    {
        heading: 'CLE Overview',
        paragraph:
            "Toolkit.law offers Earth's first global continuing legal education (CLE) webinar designed for adoption by the bar associations and councils in the 80+ countries with English-based legal systems ",
    },
    {
        heading: 'CLE Content',
        paragraph: (
            <Box>
                The free Toolkit.law CLE Webinar offers 24.0 hours of CLE credit
                with 3.0 hours of ethic credit when adopted by your local bar
                association or council. Our webinar is broken into the below 8
                sections, each with 6 presentations each lasting 30 minutes.
                Click here for our{' '}
                <ExternalLink
                    href={'https://www.toolkit.law/webinars/agenda'}
                    text="Agenda Details"
                    newTab={false}
                />
                .
                <ListItems items={agendaDeatils} />
            </Box>
        ),
    },
    {
        heading: 'United States Bar Association Jurisdictions',
        paragraph: (
            <Box>
                The Toolkit.law CLE Webinar is approved each year by the{' '}
                <ExternalLink
                    href={'http://www.texasbar.com'}
                    text="Texas Bar Association"
                    newTab={true}
                />{' '}
                and is then submitted to the English-based 1,000+ bar
                associations and councils in 80+ countries each year.
                <ListItems items={taxasList} isModal={true} />
            </Box>
        ),
    },
    {
        heading: 'Accepting USA Jurisdictions',
        paragraph: (
            <Box>
                The following bar association jurisdictions in the USA accept
                State Bar of Texas CLE approved courses without additional
                approval. Yet, we still recommend you submit it in advance for
                approval.
                <ListItems items={cleLinks} />
            </Box>
        ),
    },
    {
        heading: 'Global Bar Association & Council Jurisdictions',
        paragraph: (
            <Box>
                Toolkit.law submits our CLE Webinars the 1,000+ bar associations
                and bar councils in the 80+ countries with English-based legal
                systems. See our{' '}
                <ExternalLink
                    href={'https://www.toolkit.law/webinars/jurisdictions'}
                    text="Jurisdictions"
                    newTab={false}
                />{' '}
                page to see which of these associations and councils have
                accepted our CLE Webinar.
            </Box>
        ),
    },
    {
        heading: 'Approved Global Jurisdictions',
        paragraph: (
            <Box>
                Visit our{' '}
                <ExternalLink
                    href={'https://www.toolkit.law/webinars/jurisdictions'}
                    text="Jurisdictions"
                    newTab={false}
                />{' '}
                page for a list of all the bar associations and bar councils
                that have formally approved our CLE Webinar for that
                jurisdiction. These are organized by continent, then country,
                then jurisdiction.
            </Box>
        ),
    },
    {
        heading: 'Help Share Our CLE',
        paragraph: (
            <Box>
                Please use our{' '}
                <ExternalLink
                    href={'https://www.toolkit.law/webinars/share-cle'}
                    text="Share CLE"
                    newTab={false}
                />{' '}
                page to help share our CLE Webinar with your colleagues or
                submit our CLE Webinar to your jurisdictions bar association or
                bar council. It generates an email template with links to our
                CLE Webinar flyer packet and video packet.
            </Box>
        ),
    },
    !isIosSource && {
        heading: 'Free CLE, Tools, Calculators & Utilities ',
        paragraph:
            'Toolkit.law offers a free 6-month Trial Account that you can use to access our 1,500+ legal tools, calculators, utilities, and CLE webinars. We also offer free accounts to students and faculty of law schools. Otherwise, our Annual Account is only $100 plus processing fees per year. ',
    },
    {
        heading: 'Related CLE Weblinks',
        paragraph: <ListItems items={clePages} newTab={false} />,
    },
];
