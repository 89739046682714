import { useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Tooltip,
    Tabs,
    Tab,
    Typography,
    Button,
    useMediaQuery,
    Stack,
} from '@mui/material';
import { useEffect } from 'react';
import { Menu, MenuItem } from '@mui/material';
import {
    ArrowBackIosRounded,
    ArrowForwardIosRounded,
    DownloadRounded,
} from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CloseRounded } from '@mui/icons-material';
/**
 * Modal Component
 *
 * This component is a customizable dialog/modal used for displaying content, actions, or forms in a modal window.
 * It supports dynamic titles, body content, footers, and conditional rendering of tabs.
 *
 * Props:
 * @param {Boolean} show - Flag to control the visibility of the modal.
 * @param {String} size - The maximum width of the modal ('xs', 'sm', 'md', 'lg', 'xl'). Default is 'sm'.
 * @param {String} title - Title text to be displayed at the top of the modal.
 * @param {Function} [handleClose=null] - Display a close button at the top right of the modal if passed. Default is null.
 * @param {Function} [fullWidth=false] - Flag to set the modal to full width. Default is false.
 * @param {ReactNode} modalBody - The main content of the modal, which will be rendered in the body.
 * @param {ReactNode} [customFooter] - Optional custom footer content. If provided, it will override the default footer buttons.
 * @param {Array} [footerButtons] - An array of button objects to display in the footer. Each button should have the following structure:
 *        { title: 'Button tooltip', icon: <IconComponent />, onClick: handleClick }
 *        If customFooter is provided, this prop is ignored.
 * @param {Object} [tabs] - Optional prop to pass tab names and content.
 *        - `tabs`: Array of tab names or objects with `label` and `content` keys.
 * @param {Object} [sxBody=null] - For body styling.
 * @param {Object} [sxAction] - For action buttons styling.
 * @param {Object} rest - Additional props passed to the Dialog component (e.g., `aria-labelledby`, `className`).
 *
 * Example usage:
 * <Modal
 *    show={isOpen}
 *    size="md"
 *    title="Modal Title"
 *    modalBody={<Box>Modal Content Here</Box>}
 *    footerButtons={[
 *        { title: 'Save', icon: <SaveRounded />, onClick: handleSave },
 *        { title: 'Cancel', icon: <CancelRounded />, onClick: handleCancel }
 *    ]} // Icon Buttons
 *    footerButtons={[
 *        { label: 'Save', onClick: handleSave },
 *        { label: 'Cancel', onClick: handleCancel }
 *    ]} // Text Buttons
 *    tabs={[{ label: "Tab 1", content: "Tab 1 Content" }, { label: "Tab 2", content: "Tab 2 Content" }]}
 * />
 */

const Modal = (props) => {
    const {
        show,
        size = '',
        title,
        handleClose = null,
        fullWidth = true,
        modalBody,
        sxBody = null,
        sxAction,
        customFooter,
        footerButtons = [],
        handleSelectImage,
        options = [],
        modalLogo,
        selectedImage,
        setSelectedImage,
        toggleModal,
        isImageSlider = false,
        tabs,
        headerBtns = [],
        ...rest
    } = props;

    const [selectedTab, setSelectedTab] = useState(0);
    const [imageIndex, setImageIndex] = useState(0);
    const [modalImage, setModalImage] = useState(options?.[0]);
    let index = options.findIndex(
        (option) => option.value === modalImage?.value,
    );
    const isSmallScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('sm'),
    );
    const [menuAnchor, setMenuAnchor] = useState(null);

    const handleOpenMenu = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handlePreviousImage = () => {
        if (index > 0) {
            const newIndex = index - 1;
            setImageIndex(newIndex);
            setModalImage(options[newIndex]);
        }
    };

    const handleNextImage = () => {
        if (index < options.length - 1) {
            const newIndex = index + 1;
            setImageIndex(newIndex);
            setModalImage(options[newIndex]);
        }
    };

    const handleDownload = async () => {
        const response = await fetch(modalImage.image);
        const blob = await response.blob();

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Toolkit.law-Background-Image-${modalImage.name.replace(' ', '_')}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        setModalImage(selectedImage);
    }, [selectedImage, show]);
    const bodyImage = () => {
        if (options.length === 0) {
            return <Typography>No images available</Typography>;
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {modalImage?.name}
                </Typography>
                <Box
                    sx={{
                        width: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            transition: `transform ${
                                index * 0.05
                            }s ease-in-out`,
                            transform: `translateX(-${100 * index}%)`,
                        }}
                    >
                        {options.map((option, i) => (
                            <Box
                                component="img"
                                key={i}
                                src={`${option?.image}`}
                                alt={option.label}
                             sx={{
                                    flexShrink: 0,
                                    width:'100%',
                                    height: {
                                        xs: '250px',
                                        sm: '450px',
                                        md: '570px',
                                    },
                                    objectFit: 'cover',
                                    borderRadius: 1,
                                }}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
        );
    };

    const SliderheaderBtns = [
        {
            buttonName: 'Previous',
            icon: <ArrowBackIosRounded />,
            onClickEvent: handlePreviousImage,
            disabled: imageIndex === 0,
        },
        {
            buttonName: 'Next',
            icon: <ArrowForwardIosRounded />,
            onClickEvent: handleNextImage,
            disabled: imageIndex === options?.length - 1,
        },
        {
            buttonName: 'Download',
            icon: <DownloadRounded />,
            onClickEvent: handleDownload,
        },
        {
            buttonName: 'Select',
            onClickEvent: () => {
                if (selectedImage) {
                    handleSelectImage(setSelectedImage, modalImage);
                }
                toggleModal();
            },
            icon: <CheckBoxIcon />,
        },
    ];

    const HeaderBtns = isImageSlider ? SliderheaderBtns : headerBtns;
    const renderHeaderButtons = () => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    right: 12,
                    display: 'flex',
                }}
            >
                {HeaderBtns.map((btn, index) =>
                    btn.buttonName === 'share' ? (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {btn.icon}
                        </Box>
                    ) : (
                        <Tooltip
                            key={index}
                            title={
                                btn.buttonName.charAt(0).toUpperCase() +
                                btn.buttonName.slice(1)
                            }
                        >
                            <IconButton
                                onClick={btn.onClickEvent}
                                disabled={btn.disableBtn}
                                color="inherit"
                                sx={{ padding: isSmallScreen ? 0 : '' }}
                            >
                                {btn.icon}
                            </IconButton>
                        </Tooltip>
                    ),
                )}
                {handleClose && (
                    <Tooltip title="Close">
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseRounded />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        );
    };

    const hasIconButtons = () =>
        Array.isArray(footerButtons) &&
        footerButtons.some((button) => button.icon);

    const renderFooterButtons = () => {
        if (!Array.isArray(footerButtons) || footerButtons.length === 0) {
            return null;
        }

        const buttons =
            typeof footerButtons === 'function'
                ? footerButtons()
                : footerButtons;

        return (
            <DialogActions
                sx={{
                    justifyContent: hasIconButtons()
                        ? 'space-around'
                        : 'center',
                    flexWrap: hasIconButtons() ? 'nowrap' : 'wrap',
                    gap: hasIconButtons() ? 0 : 1,
                    m: hasIconButtons() ? 2 : 1.5,
                    border: hasIconButtons()
                        ? (theme) => `1px solid ${theme.palette.grey.dark}`
                        : 'none',
                    borderRadius: hasIconButtons() ? 1 : 0,
                    backgroundColor: hasIconButtons()
                        ? 'grey.light'
                        : 'transparent',
                    ...sxAction,
                }}
            >
                {footerButtons.map((button, index) =>
                    button.isOptions ? (
                        <Box key={index}>
                            <IconButton onClick={handleOpenMenu}>
                                {button.icon}
                            </IconButton>

                            <Menu
                                anchorEl={menuAnchor}
                                open={Boolean(menuAnchor)}
                                onClose={handleCloseMenu}
                            >
                                {button.options?.map((option, idx) => (
                                    <MenuItem
                                        key={idx}
                                        onClick={() => {
                                            setSelectedImage(option);
                                            setImageIndex(
                                                button.options.indexOf(option),
                                            );
                                            handleCloseMenu();
                                        }}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    ) : isSmallScreen && !hasIconButtons() ? (
                        <Stack
                            spacing={1}
                            direction="column"
                            alignItems="center"
                            width="100%"
                            sx={{ marginLeft: '0px !important' }}
                        >
                            <Button
                                key={index}
                                fullWidth
                                onClick={button.onClick}
                                disabled={button.disabled}
                                variant={button.variant}
                                color={button.color}
                            >
                                {button.label}
                            </Button>
                        </Stack>
                    ) : hasIconButtons() ? (
                        <Tooltip
                            key={index}
                            title={button?.title || button?.label}
                        >
                            <IconButton
                                onClick={button.onClick}
                                disabled={button.disabled}
                                color={button.color || 'inherit'}
                            >
                                {button.icon}
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Button
                            key={index}
                            onClick={button.onClick}
                            disabled={button.disabled}
                            variant={button.variant}
                            color={button.color}
                        >
                            {button.label}
                        </Button>
                    ),
                )}
            </DialogActions>
        );
    };

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            maxWidth={size}
            fullWidth={fullWidth}
            {...rest}
        >
            {modalLogo && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>{modalLogo}</Box>
            )}
            {title ? (
                <>
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1,
                                flexWrap: 'nowrap',
                                width: '100%',
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    flexGrow: 1,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontSize: isSmallScreen
                                        ? '1rem'
                                        : '1.25rem',
                                }}
                            >
                                {title}
                            </Typography>
                            {renderHeaderButtons()}
                        </Box>
                    </DialogTitle>

                    <Divider />
                </>
            ) : null}

            {(tabs || modalBody || isImageSlider) && (
                <DialogContent sx={sxBody}>
                    {/* Render Tabs if the `tabs` prop is passed */}
                    {tabs ? (
                        <>
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                aria-label="Modal Tabs"
                            >
                                {tabs.map((tab, index) => (
                                    <Tab key={index} label={tab.label} />
                                ))}
                            </Tabs>
                            <Box sx={{ paddingTop: 2 }}>
                                {tabs[selectedTab] && (
                                    <Typography variant="body1">
                                        {tabs[selectedTab].content}
                                    </Typography>
                                )}
                            </Box>
                        </>
                    ) : isImageSlider ? (
                        bodyImage()
                    ) : (
                        modalBody
                    )}
                </DialogContent>
            )}

            {customFooter ? (
                <DialogActions>{customFooter}</DialogActions>
            ) : (
                renderFooterButtons()
            )}
        </Dialog>
    );
};

export default Modal;
