import useAuthCheck from '../../../hooks/useAuthModal';
import { useAuth } from '../../../context/AuthContext';

const useHandleAuthenticatedLink = () => {
    const { isAuthenticated } = useAuth();
    const checkAuthentication = useAuthCheck();

    const handleClick = (url) => {
        if (isAuthenticated) {
            window.open(url, '_blank', 'noopener,noreferrer');
        } else {
            checkAuthentication();
        }
    };

    return handleClick;
};

export default useHandleAuthenticatedLink;
