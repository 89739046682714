import React, { lazy, Suspense, useEffect } from 'react';
import BlogLayout from './BlogLayout';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import CategoryList from './CategoryList';

const BlogList = lazy(() => import('./BlogList'));
const BlogDetails = lazy(() => import('./BlogDetails'));

const BlogPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;

        const categoryPattern = /\/blog\/category\/([^/]+)\//;

        // If the URL matches the pattern, extract the category and redirect
        const match = currentPath.match(categoryPattern);
        if (match) {
            const newPath = currentPath.replace(`/category/${match[1]}`, '');

            navigate(newPath, { replace: true });
        }
    }, [location, navigate]);

    return (
        <BlogLayout>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Suspense>
                            <BlogList />
                        </Suspense>
                    }
                />
                <Route
                    path="/:slug"
                    element={
                        <Suspense>
                            <BlogDetails />
                        </Suspense>
                    }
                />
                <Route path="/category/:category" element={<CategoryList />} />
            </Routes>
        </BlogLayout>
    );
};

export default BlogPage;
