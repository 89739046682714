import React, { useRef } from 'react';
import { handleMailTo } from '../../utils/Utils';
import { Box, Container, Typography } from '@mui/material';
import { useAuth } from '../../context/AuthContext';

const Data = ({ mainHeading, heading, paragraph, emailData = {} }) => {
    const { user } = useAuth();
    const contentRef = useRef(null);

    const replacePlaceholders = (text) => {
        return text.replace(/{{(.*?)}}/g, (_, key) => {
            const trimmedKey = key.trim();
            return user.user[trimmedKey] || '';
        });
    };

    const handleMailtoClick = (e) => {
        const link = e.target;
        if (
            link.tagName === 'A' &&
            link.getAttribute('href')?.startsWith('mailto:')
        ) {
            if (emailData && Object.keys(emailData).length > 0) {
                e.preventDefault();
                const body = replacePlaceholders(emailData.body);
                handleMailTo(emailData.subject, body, emailData.to);
            }
        }
    };

    return (
        <Box ref={contentRef} onClick={handleMailtoClick}>
            {mainHeading && (
                <Typography variant="h5" align="center">
                    {mainHeading}
                </Typography>
            )}
            {heading && (
                <Typography variant="subtitle1" fontWeight="bold">
                    {heading}
                </Typography>
            )}
            {paragraph && <Typography mb={2}>{paragraph}</Typography>}
        </Box>
    );
};

export default Data;
