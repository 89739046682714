import { Reply } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ReturnBack = (props) => {
    const { pageTitle } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const location = useLocation();

    // Function to get the parent URL
    const getParentUrl = (currentPath) => {
        const pathSegments = currentPath.split('/').filter(Boolean);

        if (pathSegments.length > 1) {
            pathSegments.pop(); // Remove the last segment

            if (
                pathSegments.length > 0 &&
                pathSegments[pathSegments.length - 1] === 'subpage'
            ) {
                return '/'; // If the second-to-last segment is 'subPage', return root
            }
        } else {
            // If there's only one segment or none, return root
            return '/';
        }

        // Join the remaining segments and return the parent URL
        return `/${pathSegments.join('/')}`;
    };

    const handleBackButtonClick = () => {
        const parentUrl = getParentUrl(location.pathname);
        navigate(parentUrl);
    };

    return (
        <Box>
            <Box
                sx={{
                    position: 'fixed',
                    top: isMobile ? '55px' : '85px',
                    width: '100%',
                    backgroundColor: 'white', // Optional for better visibility
                    zIndex: 1000, // Ensures it stays above other elements
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <IconButton
                    sx={{
                        backgroundColor: '#f7971c',
                        borderRadius: '10%',
                        padding: '.34rem .55rem',
                        left: '12px',
                    }}
                    onClick={handleBackButtonClick}
                    title="Back"
                >
                    <Reply />
                </IconButton>
                <Typography
                    sx={{
                        flexGrow: 1,
                        textAlign: 'center',
                        fontSize: '2rem',
                        fontWeight: '500',
                        paddingBottom: '10px',
                    }}
                >
                    {pageTitle}
                </Typography>
            </Box>
        </Box>
    );
};

export default ReturnBack;
