import { useCallback, useContext, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Tabs, Tab, Box, Typography, Button, IconButton } from '@mui/material';
import { CheckCircleRounded, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import SEO from '../../reusable/SEO';
import { AlertMessageContext } from '../../../context/AlertMessageContext';
import { UserSettingsContext } from '../../../context/UserSettingsContext';
import publicAxios, { userAxios } from '../../../utils/api';
import { config } from '../../../config/config';
import { logger } from '../../../utils/SiteHelpers';
import { PlanIds } from '../../../utils/constants';
import { checkEnv } from '../../../utils/Utils';
import PlanData from './PlanData';
import LogoutButton from '../../settings/accountInformation/LogoutButton';
import PDFViewer from '../../reusable/PDFViewer';
import MarketingVideo from '../../../pages/about/MarketingVideo';
import AccountTypes from '../../../pages/about/AccountTypes';
import AccountPlanSekelton from '../../skeleton/payment/AccountPlansSkeleton';
import { useAuth } from '../../../context/AuthContext';

const AccountPlans = (props) => {
    const { isNested = false } = props;
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(0);
    const { isAuthenticated, user, loginWithRedirect, userId, accessToken } =
        useAuth();
    const { setErrorMessage } = useContext(AlertMessageContext);
    const { userSettings } = useContext(UserSettingsContext);
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const { subscriptionUrl, siteName, canonicalUrl } = config;
    const isProduction = checkEnv();
    const tabsToShow = 2; // Number of tabs to show on mobile

    const fetchSubscriptions = useCallback(async () => {
        const apiUrl = `${subscriptionUrl}listing`;

        try {
            const { data, status } = await publicAxios.get(apiUrl);
            const { subscriptions } = data;

            if (status === 200 && subscriptions) {
                const overviewPlan = {
                    id: 0,
                    name: 'Overview',
                    content: <AccountTypes />,
                };

                const updatedSubscriptions = [overviewPlan, ...subscriptions];

                setSubscriptions(updatedSubscriptions);
            } else {
                throw new Error('Failed to fetch subscription data.');
            }
        } catch (error) {
            logger.error('Error fetching subscriptions:', error);
            setErrorMessage(
                'Failed to fetch subscriptions. Please try again later.',
            );
        } finally {
            setLoading(false);
        }
    }, [setErrorMessage]);

    const fetchSubscriptionAccess = useCallback(async () => {
        try {
            if (!accessToken) {
                throw new Error('No access token available.');
            }

            const apiUrl = `${subscriptionUrl}access`;
            const payload = { user_id: userId };
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            const { data } = await userAxios.post(apiUrl, payload, config);

            if (data?.data?.[0]?.subscription_id) {
                setSubscriptionId(data.data[0].subscription_id);
            }
        } catch (error) {
            logger.error('Error fetching subscription access:', error);
        }
    }, [user]);

    useEffect(() => {
        const loadData = async () => {
            if (isAuthenticated) {
                await fetchSubscriptionAccess();
            }
            await fetchSubscriptions();
        };
        loadData();
    }, [isAuthenticated, fetchSubscriptions, fetchSubscriptionAccess]);

    const isTrialExpired = (subscriptionId) => {
        return subscriptionId === PlanIds.TRIAL && userSettings.trial === '1';
    };

    const isCurrentSubscription = (currentId) => {
        return subscriptionId === currentId;
    };

    const getTabLabel = (planId, planName) => {
        const planIdMapping = {
            [PlanIds.SPECIAL]: 'Special',
            [PlanIds.TRIAL]: 'Free',
            [PlanIds.MONTHLY]: 'Monthly',
            [PlanIds.ANNUAL]: 'Annual',
        };

        return planIdMapping[planId] || planName;
    };

    const a11yProps = (index) => ({
        id: `plan-tab-${index}`,
        'aria-controls': `plan-tabpanel-${index}`,
    });

    const preferredPlansOrder = [
        PlanIds.TRIAL,
        PlanIds.MONTHLY,
        PlanIds.ANNUAL,
        PlanIds.SPECIAL,
    ];

    const sortedSubscriptions = subscriptions.sort(
        (a, b) =>
            preferredPlansOrder.indexOf(a.id) -
            preferredPlansOrder.indexOf(b.id),
    );

    const handlePrevTab = () => {
        if (startIndex > 0) {
            const newStartIndex = Math.max(0, startIndex - tabsToShow);
            setStartIndex(newStartIndex);
            setSelectedTab(newStartIndex);
        }
    };

    const handleNextTab = () => {
        if (startIndex + tabsToShow < sortedSubscriptions.length) {
            const newStartIndex = startIndex + tabsToShow;
            setStartIndex(newStartIndex);
            setSelectedTab(newStartIndex);
        }
    };
    const getVisibleTabs = () => {
        const endIndex = Math.min(startIndex + tabsToShow, sortedSubscriptions.length);
        return sortedSubscriptions.slice(startIndex, endIndex);
    };

    return (
        <>
            {!isNested && (
                 <SEO
                 pageTitle={`Pricing | ${siteName}`}
                 description={'Toolkit.law Billing Plans, Legal Software Subscription Options, Toolkit.law Pricing Plans, Affordable Legal Software Pricing, Subscription Plans for Legal Professionals, Toolkit.law Payment Options, Legal Service Plans, Monthly Billing Plans for Law Firms, Annual Legal Software Plans, Toolkit.law Features and Pricing, Legal Technology Subscription Rates, Compare Legal Software Pricing, Toolkit.law Plan Details, Flexible Billing Plans for Lawyers, Toolkit.law Membership Options, Cost-Effective Legal Solutions, Toolkit.law Plan Benefits, Legal Software Payment Plans, Toolkit.law Subscription Tiers, Budget-Friendly Legal Software Options, Legal Services Pricing Guide, Toolkit.law Payment Plans Overview, Legal Software Pricing Comparison, Comprehensive Legal Billing Solutions, Toolkit.law Subscription Benefits'}
                 keywords={`Toolkit.law Billing Plans, Legal Software Subscription Options, Toolkit.law Pricing Plans, Affordable Legal Software Pricing, Subscription Plans for Legal Professionals, Toolkit.law Payment Options, Legal Service Plans, Monthly Billing Plans for Law Firms, Annual Legal Software Plans, Toolkit.law Features and Pricing, Legal Technology Subscription Rates, Compare Legal Software Pricing, Toolkit.law Plan Details, Flexible Billing Plans for Lawyers, Toolkit.law Membership Options, Cost-Effective Legal Solutions, Toolkit.law Plan Benefits, Legal Software Payment Plans, Toolkit.law Subscription Tiers, Budget-Friendly Legal Software Options, Legal Services Pricing Guide, Toolkit.law Payment Plans Overview, Legal Software Pricing Comparison, Comprehensive Legal Billing Solutions, Toolkit.law Subscription Benefits`}
                 />
            )}
            {loading ? (
                <AccountPlanSekelton isNested={true} />
            ) : (
                <Box sx={{ width: '100%', p: !isNested ? '0 16px 16px' : 0 }}>
                    {!isNested && (
                        <Box
                            position="relative"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                        >
                            <Typography
                                variant="h4"
                                component="h3"
                                sx={{
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                }}
                            >
                                Pricing
                            </Typography>
                            <Box ml="auto">
                                {isAuthenticated ? (
                                    <LogoutButton />
                                ) : (
                                    <Button onClick={loginWithRedirect}>Login</Button>
                                )}
                            </Box>
                        </Box>
                    )}
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                        {/* Mobile View: Arrows with sliding tabs */}
                        <Box
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <IconButton
                                onClick={handlePrevTab}
                                disabled={startIndex === 0}
                                sx={{ color: 'primary.main', padding: { xs: '4px', sm: '8px' } }}
                            >
                                <ArrowBackIos fontSize="small" />
                            </IconButton>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    flexGrow: 1,
                                    overflowX: 'auto',
                                    paddingRight: { xs: 1, sm: 2 },
                                }}
                            >
                                {getVisibleTabs().map((subscription) => (
                                    <Box
                                        key={subscription.id}
                                        onClick={() =>
                                            setSelectedTab(sortedSubscriptions.indexOf(subscription))
                                        }
                                        sx={{
                                            flex: 1,
                                            minWidth: 0,
                                            fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                            padding: { xs: '6px 10px', sm: '12px 16px' },
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                            borderBottom:
                                                sortedSubscriptions[selectedTab].id === subscription.id
                                                    ? '1px solid'
                                                    : 'none',
                                            borderColor: 'primary.main',
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        {isCurrentSubscription(subscription.id) && (
                                            <CheckCircleRounded color="primary" fontSize="small" />
                                        )}
                                        {getTabLabel(subscription.id, subscription.name)}
                                    </Box>
                                ))}
                            </Box>
                            <IconButton
                                onClick={handleNextTab}
                                disabled={startIndex + tabsToShow >= sortedSubscriptions.length}
                                sx={{ color: 'primary.main', padding: { xs: '4px', sm: '8px' } }}
                            >
                                <ArrowForwardIos fontSize="small" />
                            </IconButton>
                        </Box>

                        {/* Desktop View: All Tabs */}
                        <Tabs
                            value={selectedTab}
                            onChange={(_, newValue) => setSelectedTab(newValue)}
                            variant="scrollable"
                            scrollButtons={false}
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                width: '100%',
                                minHeight: '36px', // Reduced height
                                '& .MuiTabs-flexContainer': {
                                    justifyContent: !isNested ? 'center' : 'flex-start',
                                },
                                '& .MuiTabs-indicator': {
                                    height: 2, // Thinner indicator
                                    backgroundColor: 'primary.main', // Match your theme
                                },
                            }}
                        >
                            {sortedSubscriptions.map((subscription) => (
                                <Tab
                                    key={subscription.id}
                                    label={getTabLabel(subscription.id, subscription.name)}
                                    icon={
                                        isCurrentSubscription(subscription.id) ? (
                                            <CheckCircleRounded color="primary" fontSize="small" />
                                        ) : null
                                    }
                                    iconPosition="start"
                                    {...a11yProps(subscription.id)}
                                    sx={{
                                        minHeight: '36px', // Reduced height
                                        padding: '6px 12px', // Reduced padding
                                        fontSize: '0.875rem',
                                        textTransform: 'none',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        '& .MuiTab-iconWrapper': {
                                            marginRight: '4px', // Reduced spacing between icon and label
                                        },
                                    }}
                                />
                            ))}
                        </Tabs>
                    </Box>

                    {sortedSubscriptions.map((subscription, index) => (
                        <Box key={subscription.id} hidden={selectedTab !== index}>
                            <PlanData
                                subscription={subscription}
                                isTrialExpired={isTrialExpired(subscription.id)}
                                isNested={isNested}
                                isCurrentSubscription={isCurrentSubscription(subscription.id)}
                                currentSubscriptionId={subscriptionId}
                            />
                        </Box>
                    ))}
                    {sortedSubscriptions[selectedTab] &&
                        !sortedSubscriptions[selectedTab].content && (
                            <Box mt={2}>
                                <MarketingVideo
                                    videoId={
                                        selectedTab === 4
                                            ? "1tTFueDwJcdSccL35N7YHq5bv57jegxw9"
                                            : "1DNVem8uF50cu7QDwmoiUEg1eCyxuUnai"
                                    }
                                    thumbnailId="1iEeMT8Fw5EqwsneTOEs7F2-swhqjLl58"
                                />
                                <PDFViewer flyerId="1S_pnDGh0Bp9eyCiDrjx2xprYx5LlLAJm" />
                            </Box>
                        )}
                </Box>
            )}
        </>
    );
};

export default AccountPlans;