import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { config } from '../config/config';
import { accountInformationTitle } from '../pages/settings/schema';
import { generateSlug } from '../utils/SiteHelpers';
import { userAxios } from '../utils/api';
import { getAuthData } from '../utils/auth';

const Auth0ProviderWithHistory = ({ children }) => {
    const navigate = useNavigate();
    const { auth0Domain, auth0ClientId, auth0RedirectUrl, canonicalUrl } =
        config;

       
        const onRedirectCallback = async (appState, user) => {
            try {
              const isNewUser = user?.[`${canonicalUrl}/isNewUser`];
          
              if (!isNewUser) {
                // Redirect to home or the specified route
                return navigate(appState?.returnTo || '/');
              }
          
              // Check for subscription info in localStorage
              const storedPayload = localStorage.getItem('subscriptionInfo');
              if (!storedPayload) {
                return navigate(`/settings?accordion=${generateSlug(accountInformationTitle)}`);
              }
          
              // Parse and remove subscription info from localStorage
              const subscriptionInfo = JSON.parse(storedPayload);
              localStorage.removeItem('subscriptionInfo');
          
              // Prepare API request data
              const apiUrl = `${config.subscriptionUrl}create-session`;
              const { email, sub } = user;
              const authData = await getAuthData(email, sub);
          
              const payload = {
                user_id: authData.userId,
                coupon: subscriptionInfo.coupon ?? null,
                trial: 0,
                subscription_id: subscriptionInfo.subscription_id,
                payment_method: subscriptionInfo.payment_method ,
              };
          
              // API request
              const { data } = await userAxios.post(apiUrl, payload, {
                headers: {
                  Authorization: `Bearer ${authData.accessToken}`,
                  'Content-Type': 'application/json',
                },
              });
          
              if (data?.error) {
                console.error("API Error:", data.error);
                return navigate(`/settings?accordion=${generateSlug(accountInformationTitle)}`);
              }
          
              window.location = data.redirectUrl;
          
            } catch (error) {
              console.error("Unexpected Error:", error);
              navigate(`/settings?accordion=${generateSlug(accountInformationTitle)}`);
            }
          };

    return (
        <Auth0Provider
            domain={auth0Domain}
            clientId={auth0ClientId}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            authorizationParams={{
                redirect_uri: auth0RedirectUrl,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
