import React from 'react';
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { config } from '../../config/config';
import { isIosSource } from '../content/webnars/basicInformation/schema';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({
    fileUrl,
    flyerId,
    width,
    height,
    pageSize = 'PageWidth',
    heading,
}) => {


    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const plugins = [defaultLayoutPluginInstance];

    // Get theme and set media query
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const driveFlyerUrl = `${process.env.REACT_APP_GOOGLE_DRIVE_API}${flyerId}`;

    const hiddenFlyerIds = [
        '1SRZCcwiuOjuJ0gWPvDjNE_wjHKmn336r',
        '19XqvvVNaDKYNdM-20pAw9d2v-oF15Yk0',
        '1AEsPiH9YyqmpeRc2Q0fv_UHejVbg2fw3',
        '1dPA4NnY8IZAU3hmfrsstEw8mfHNazft9',
        '19YUcx8QGogvRxOOwj2RJM_7cBFypAOQK',
        '1zDiUntJ4o_Er5_jnluBzVUTtUCpIEohX',
        '19jHAMC90bzByKgwdDF0BEXcL62Hxy4J6',
        '1GcNZxiXX7qesHPiWNh7eMoh_rOlKlg3H',
        '1SMIDZQSiAzlqTaJbGIVvtaN4KOUkAwQu',
        '1uuJ9Dp67pD55W46spweWwU7gOR_AIMuS',
        '1dNG-2veMJ0l6yk_ui-BznvpgmeXc9l15',
        '1h8rD9_KfgVMLKJtFZWjgLnPxBC-F-2sS',
        '1OlNyOXJmp8drwgDko1kY8zNss2qTSu5Z',
        '1dEou0hFnH_Ee6ljHpXPRRtxzdarufFx3',
        '1jpT_j1SWsjwbc_pHEPetUPMRcVhtZbDf',
        '1WfHaPBf0f3SfOp2WXH0uez79gW_IVom2',
        '1BxEhAjJLiiaa6jg_d6okN6VWtPuElLLZ',
        '1Gmx1hVhLbCLI4pyeKXmCI238q8pXjowg',
        '1KIc7DERIAJTjltqyTL7xKEE21LtZP7QJ',
        '1Gmd-y1soSTaYhwzE9R90deXAzIJUZgwT',
        '1gf2u8yhYgNrNGJHQAU4Zx7GHQxElEt_4',
        '1lD2R8153a6kg4yxbCBrvIFafEmk9V6yh',
        '1kIyomX8-PziutzrYgdyLT29A-0eK0X1o',
    ];

    if (isIosSource && hiddenFlyerIds.includes(flyerId)) {
        return null; // or return a message like <Typography>No PDF available for iOS</Typography>
    }
   
    return (
        <Box
            sx={{
                width: isMobile ? '100%' : width ? width : '58%',
                height: isMobile ? '50vh' : height ? height : '100vh',
                overflow: 'hidden',
                display: 'block',
                margin: '0 auto',
            }}
        >
            {heading && (
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    align="center"
                    mb={2}
                >
                    {heading}
                </Typography>
            )}
            <Viewer
            
                theme="dark"
                fileUrl={flyerId ? driveFlyerUrl : fileUrl}
                plugins={plugins}
                defaultScale={
                    // pageSize === 'PageWidth'
                    //     ? SpecialZoomLevel.PageWidth
                    //     : SpecialZoomLevel.pageSize
                    SpecialZoomLevel.PageWidth
                }
            />
        </Box>
    );
};

export default PDFViewer;
