import { lazy, Suspense, useEffect, useState } from 'react';

import { Container, Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import ProfilePageSkeleton from '../../components/skeleton/profilePage/ProfilePageSkeleton';
import publicAxios from '../../utils/api';
import Loader from '../../components/reusable/Loader/Loader';
import { logger } from '../../utils/SiteHelpers';
import { checkEnv } from '../../utils/Utils';
import { config } from '../../config/config';
import { useAuth } from '../../context/AuthContext';

const NotFoundPage = lazy(() => import('../NotFoundPage'));
const About = lazy(() => import('../../components/profilePage/About'));
const Services = lazy(() =>
    import('../../components/profilePage/services/Services'),
);
const Education = lazy(() => import('../../components/profilePage/Education'));
const Heading = lazy(() => import('../../components/profilePage/Heading'));
const BasicInformation = lazy(() =>
    import('../../components/profilePage/basicInformation/BasicInformation'),
);
const ToolkitLinks = lazy(() =>
    import('../../components/profilePage/ToolkitLinks'),
);
const CopyrightText = lazy(() =>
    import('../../components/includes/footer/CopyrightText'),
);
const DatingLinks = lazy(() =>
    import('../../components/profilePage/DatingLinks'),
);
const AccountModal = lazy(() =>
    import('../../components/reusable/modal/AccountModal'),
);

const ProfilePage = () => {
    const { user_slug } = useParams();
    const location = useLocation();
    const { user, userId } = useAuth();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const isProduction = checkEnv();
    const isIndividual =
        userData?.user_setting?.profile_display === 'individual';
    const { siteName, canonicalUrl } = config;

    useEffect(() => {
        const getUserData = async () => {
            try {
                const response = await publicAxios.post('users/user-profile', {
                    user_slug,
                });

                if (response.status !== 200) {
                    throw new Error(
                        `Unexpected response status: ${response.status}`,
                    );
                }

                setUserData(response.data.data);
            } catch (error) {
                setError(true);
                logger.error(`Error fetching user data: ${error}`);
            } finally {
                setLoading(false);
            }
        };

        getUserData();
    }, [user_slug]);

    if (loading) {
        return <ProfilePageSkeleton />;
    }

    const isDifferentUser = Number(userId) !== userData?.id;

    if (
        error ||
        !userData ||
        !userData?.user_setting ||
        (userData?.user_setting?.webstore_visibility === 'No' &&
            user &&
            isDifferentUser)
    ) {
        return <NotFoundPage />;
    }

    const getUsername = () => {
        const indName =
            userData?.given_name && userData?.family_name
                ? `${userData.given_name} ${userData.family_name}`
                : userData?.name
                ? userData.name
                : 'User';

        const orgName =
            userData?.user_setting?.prof_company_name || 'Organization';

        return isIndividual ? indName : orgName;
    };

    const getSeoTitle = () => {
        return `${getUsername()} | Powered by ${siteName}`;
    };

    const getDescription = () => {
        const description = userData?.user_setting?.about
            ? userData.user_setting.about
            : `${getUsername()}'s profile on Toolkit.law. Legal services, mediation, and more.`;
        return description;
    };

    const profileImage = () => {
        // Replace 's96-c' with 's1024-c' for higher resolution
        return userData.image?.includes('s96-c')
            ? userData.image.replace('s96-c', 's1024-c')
            : userData.image;
    };

    const renderAboutSection = () => {
        if (userData?.user_setting?.about) {
            return (
                <Grid item xs={12}>
                    <About data={userData.user_setting.about} />
                </Grid>
            );
        }
        return null;
    };

    const renderServicesSection = () => {
        const data = userData?.services;
        if (userData && data.length) {
            return (
                <Grid item xs={12}>
                    <Services
                        data={userData}
                        isDifferentUser={isDifferentUser}
                    />
                </Grid>
            );
        }
        return null;
    };

    const renderEducationSection = () => {
        const data = userData?.education;
        if (data.length && isIndividual) {
            return (
                <Grid item xs={12}>
                    <Education data={data} />
                </Grid>
            );
        }
        return null;
    };

    const renderConditionalSections = () => {
        const sectionOrder = userData?.user_setting?.section_order;

        if (sectionOrder === 'services') {
            return (
                <>
                    {renderServicesSection()}
                    {renderAboutSection()}
                </>
            );
        } else if (sectionOrder === 'about') {
            return (
                <>
                    {renderAboutSection()}
                    {renderServicesSection()}
                </>
            );
        }
        return null;
    };

    const renderCopyrightSection = () => {
        if (userData?.public_settings?.copy_right) {
            return (
                <Grid item xs={12}>
                    <CopyrightText
                        copyright={userData.public_settings.copy_right}
                        alignmentSetting={1}
                    />
                </Grid>
            );
        }
        return null;
    };

    return (
        <>
            <Helmet>
                <title>{getSeoTitle()}</title>
                <meta name="description" content={getDescription()} />
                <meta name="keywords" content={getDescription()} />
                <link
                    rel="canonical"
                    href={`${canonicalUrl}${location.pathname}`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={
                        userData.url +
                        '/images/header/' +
                        userData.public_settings.public_fav_icon
                    }
                    sizes="16x16"
                />

                {isProduction && (
                    <>
                        <meta
                            name="keywords"
                            content="toolkit.law, legal services, mediation services, arbitration services, legal profile"
                        />

                        <meta property="og:type" content="profile" />
                        <meta property="og:title" content={getSeoTitle()} />
                        <meta
                            property="og:description"
                            content={getDescription()}
                        />
                        <meta property="og:image" content={userData?.image} />
                        <meta
                            property="og:url"
                            content={`${canonicalUrl}${location.pathname}`}
                        />
                        <meta
                            property="profile:first_name"
                            content={userData?.given_name || ''}
                        />
                        <meta
                            property="profile:last_name"
                            content={userData?.family_name || ''}
                        />
                        <meta
                            property="profile:username"
                            content={userData?.name || ''}
                        />
                        <meta
                            property="profile:gender"
                            content={userData?.gender || ''}
                        />

                        <meta
                            name="twitter:card"
                            content="summary_large_image"
                        />
                        <meta name="twitter:title" content={getSeoTitle()} />
                        <meta
                            name="twitter:description"
                            content={getDescription()}
                        />
                        <meta name="twitter:image" content={userData?.image} />
                        <meta name="twitter:site" content="@ToolkitLaw" />

                        <script type="application/ld+json">
                            {JSON.stringify({
                                '@context': 'https://schema.org',
                                '@type': 'Person',
                                name: `${getUsername()}`,
                                url: `${canonicalUrl}${location.pathname}`,
                                jobTitle: userData?.profession,
                                image: userData?.image,
                                description: `${getDescription()}`,
                                sameAs: [
                                    userData?.facebook_url || '',
                                    userData?.instagram_url || '',
                                    userData?.linkedin_url || '',
                                    userData?.pinterest_url || '',
                                    userData?.twitter_url || '',
                                    userData?.youtube_url || '',
                                ],
                            })}
                        </script>
                    </>
                )}
            </Helmet>
            <Container maxWidth="lg" sx={{ py: 3 }}>
                <Loader />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Heading
                            userData={userData}
                            profileImage={profileImage}
                        />
                    </Grid>
                    {renderConditionalSections()}
                    {renderEducationSection()}
                    <Grid item sm={6} xs={12}>
                        <BasicInformation
                            userData={userData}
                            isDifferentUser={isDifferentUser}
                            profileImage={profileImage}
                        />
                        <DatingLinks userData={userData} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <ToolkitLinks />
                    </Grid>
                    {renderCopyrightSection()}
                </Grid>
            </Container>
            <Suspense fallback={<div>Loading...</div>}>
                <AccountModal />
            </Suspense>
        </>
    );
};

export default ProfilePage;
