import React, { useState, useEffect } from 'react';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { config } from '../../config/config'; // Adjust path if moved to src/
import { checkEnv } from '../../utils/Utils';

function SEO({
    pageTitle,
    description,
    keywords,
    videoId,
    thumbnailId,
    favIcon,
    globalAds = [], // Default to empty array if not provided
    adRotation = { ads_rotation: 'daily' } // Default to a safe object
}) {
    const isMobile = () => window.innerWidth <= 768;

    const location = useLocation();
    const isProduction = checkEnv();
    const { siteUrl, siteName, canonicalUrl, imageUrl } = config;
    const [currentAdIndex, setCurrentAdIndex] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [deviceType, setDeviceType] = useState(isMobile() ? 'mobile' : 'desktop');

    const pathSegments = location.pathname.split('/').filter(Boolean);
    const secondSegment = pathSegments[0] || '';
    const thirdSegment = pathSegments[1] || '';
    const slug = `${secondSegment}/${thirdSegment}`;
    const pageURL = `${siteUrl}/${slug}`;
    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: pageTitle,
        description: description,
        url: pageURL,
        keywords: keywords,
    };

    // Helper function to get the day index for daily ad rotation
    const getDailyAdIndex = (images) => {
        const now = new Date();
        const startOfYear = new Date(now.getFullYear(), 0, 0);
        const diffInMs = now - startOfYear;
        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
        const oneDayInMinutes = 1440;
        const dayOfYear = Math.floor(diffInMinutes / oneDayInMinutes);
        return dayOfYear % images.length;
    };

    useEffect(() => {
        const handleResize = () => {
            const newDeviceType = isMobile() ? 'mobile' : 'desktop';
            if (newDeviceType !== deviceType) {
                setDeviceType(newDeviceType);
                setCurrentAdIndex(0);
                setCurrentImageIndex(0);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [deviceType]);

    useEffect(() => {
        const adRotationType = adRotation?.ads_rotation || 'daily';
        const currentAd = globalAds[currentAdIndex];

        if (currentAd?.ad_images?.length > 0) {
            const images = currentAd.ad_images;
            if (adRotationType === 'daily') {
                const index = getDailyAdIndex(images);
                setCurrentImageIndex(index);
            } else if (adRotationType === 'continually') {
                const randomIndex = Math.floor(Math.random() * images.length);
                setCurrentImageIndex(randomIndex);
            }
        }
    }, [deviceType, adRotation, currentAdIndex, globalAds]);

    const getAdsForDevice = () => {
        if (!Array.isArray(globalAds)) return [];
        return globalAds.filter((ad) => ad.ad_visibility === 1);
    };

    const ads = getAdsForDevice();
    const currentAd = ads[currentAdIndex];
    const currentAdImage = currentAd?.ad_images?.[currentImageIndex];

    const imageUrlForAd = currentAdImage
        ? `${config.bannerImage}${
              deviceType === 'mobile'
                  ? currentAdImage.ad_mobile_image_url
                  : currentAdImage.ad_desktop_image_url
          }`
        : '';
    const defaultPageImage = `${imageUrl}/toolkitlaw-basic-logo-svg-square.svg`;

    return (
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href={`${canonicalUrl}${location.pathname}`} />
            {favIcon && (
                <link rel="icon" type="image/png" href={favIcon} sizes="16x16" />
            )}

            {isProduction && [
                // Open Graph (Facebook, LinkedIn, Telegram)
                <meta key="og:title" property="og:title" content={pageTitle} />,
                <meta
                    key="og:description"
                    property="og:description"
                    content={description}
                />,
                <meta key="og:url" property="og:url" content={pageURL} />,
                (imageUrlForAd || defaultPageImage) && (
                    <meta key="og:image" property="og:image" content={imageUrlForAd || defaultPageImage} />
                ),
                (imageUrlForAd || defaultPageImage) && (
                    <meta
                        key="og:image:alt"
                        property="og:image:alt"
                        content={pageTitle}
                    />
                ),
                <meta key="og:type" property="og:type" content="website" />,
                <meta
                    key="og:site_name"
                    property="og:site_name"
                    content={siteName}
                />,

                // Twitter/X Card
                <meta
                    key="twitter:card"
                    name="twitter:card"
                    content="summary_large_image"
                />,
                <meta
                    key="twitter:title"
                    name="twitter:title"
                    content={pageTitle}
                />,
                <meta
                    key="twitter:description"
                    name="twitter:description"
                    content={description}
                />,
                (imageUrlForAd || defaultPageImage) && (
                    <meta
                        key="twitter:image"
                        name="twitter:image"
                        content={imageUrlForAd || defaultPageImage}
                    />
                ),
                (imageUrlForAd || defaultPageImage) && (
                    <meta
                        key="twitter:image:alt"
                        name="twitter:image:alt"
                        content={pageTitle}
                    />
                ),
                <meta
                    key="twitter:site"
                    name="twitter:site"
                    content="@toolkit.law"
                />,
                <meta
                    key="twitter:creator"
                    name="twitter:creator"
                    content="@toolkit.law"
                />,
                <meta
                    key="twitter:domain"
                    property="twitter:domain"
                    content={siteUrl}
                />,
                <meta
                    key="twitter:url"
                    property="twitter:url"
                    content={pageURL}
                />,

                // WhatsApp (Open Graph)
                (imageUrlForAd || defaultPageImage) && (
                    <meta
                        key="og:image:whatsapp"
                        property="og:image"
                        content={imageUrlForAd || defaultPageImage}
                    />
                ),
                (imageUrlForAd || defaultPageImage) && (
                    <meta
                        key="og:image:type"
                        property="og:image:type"
                        content="image/jpeg"
                    />
                ),
                (imageUrlForAd || defaultPageImage) && (
                    <meta
                        key="og:image:width"
                        property="og:image:width"
                        content="1200"
                    />
                ),
                (imageUrlForAd || defaultPageImage) && (
                    <meta
                        key="og:image:height"
                        property="og:image:height"
                        content="630"
                    />
                ),

                // Telegram (Open Graph)
                (imageUrlForAd || defaultPageImage) && (
                    <meta
                        key="og:image:telegram"
                        property="og:image"
                        content={imageUrlForAd || defaultPageImage}
                    />
                ),

                // YouTube (Open Graph)
                videoId && thumbnailId && [
                    <meta
                        key="og:title:video"
                        property="og:title"
                        content={pageTitle}
                    />,
                    <meta
                        key="og:description:video"
                        property="og:description"
                        content={description}
                    />,
                    <meta
                        key="og:url:video"
                        property="og:url"
                        content={pageURL}
                    />,
                    <meta
                        key="og:image:video"
                        property="og:image"
                        content={`https://drive.google.com/file/d/${thumbnailId}/view?usp=sharing`}
                    />,
                    <meta
                        key="og:image:alt:video"
                        property="og:image:alt"
                        content={pageTitle}
                    />,
                    <meta
                        key="og:type:video"
                        property="og:type"
                        content="video.other"
                    />,
                    <meta
                        key="og:video"
                        property="og:video"
                        content={`https://drive.google.com/file/d/${videoId}/view?usp=sharing`}
                    />,
                    <meta
                        key="og:video:type"
                        property="og:video:type"
                        content="text/html"
                    />,
                    <meta
                        key="og:video:width"
                        property="og:video:width"
                        content="640"
                    />,
                    <meta
                        key="og:video:height"
                        property="og:video:height"
                        content="360"
                    />,
                    <meta
                        key="og:site_name:video"
                        property="og:site_name"
                        content={siteName}
                    />,
                ],

                // Structured Data (Schema.org)
                <script
                    key="structured-data"
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(structuredData),
                    }}
                />,
            ].filter(Boolean)} {/* Filter out falsy values */}
        </Helmet>
    );
}

export default memo(SEO);