import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { capitalize } from '../../utils/Utils';
import { generateSlug } from '../../utils/SiteHelpers';

const BlogBreadcrumbs = () => {
    const { category, slug } = useParams();

    // Define breadcrumb links
    const breadcrumbs = [{ label: 'Home', path: '/blog' }];

    if (category) {
        breadcrumbs.push({
            label: capitalize(category),
            path: `/blog/category/${generateSlug(category)}`,
        });
    }

    if (slug) {
        breadcrumbs.push({
            label: slug.replace(/-/g, ' '),
            path: `/blog/${slug}`,
        });
    }

    return (
        <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
            sx={{
                mt: 3,
                ml: 4.5,
                width: '100%',
            }}
        >
            {breadcrumbs.map((crumb, index) =>
                index !== breadcrumbs.length - 1 ? (
                    <Link
                        key={crumb.path}
                        component={RouterLink}
                        to={crumb.path}
                        sx={(theme) => ({
                            textDecoration: 'none',
                            color: theme.palette.primary.main, // Correct way to access theme colors
                            '&:hover': {
                                color: theme.palette.primary.dark, // Add hover effect
                            },
                        })}
                    >
                        {crumb.label}
                    </Link>
                ) : (
                    <Typography key={crumb.path} color="text.primary">
                        {crumb.label}
                    </Typography>
                ),
            )}
        </Breadcrumbs>
    );
};

export default BlogBreadcrumbs;
