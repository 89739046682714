import {
    Preview,
    ArrowBackRounded,
    ArrowForwardRounded,
} from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Modal from '../modal/Modal';
import { Document, Page, pdfjs } from 'react-pdf';
import TooltipWrapper from '../TooltipWrapper';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FilePreviewModal = ({
    fileUrl,
    pdfFile,
    headerBtns,
    previewImage,
    viewportWidth = Math.min(window.innerWidth * 0.9, 400),
    setPreviewImage,
    thumbnail,
    pageNum,
    openByDefault = false,
    onClose,
    file,
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        if (openByDefault) {
            handlePreviewClick(thumbnail);
        }
    }, []);

    const handlePreviewClick = async (thumbnail) => {
        if (thumbnail) {
            setPreviewImage(thumbnail);
        }
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
        setPageNumber(1); // Reset page number on close
        if (setPreviewImage) {
            setPreviewImage('');
        }
    };

    const generateFileUrl = (file) => {
        if (file && (file instanceof Blob || file instanceof File)) {
            return URL.createObjectURL(file);
        }
        return null;
    };

    return (
        <>
            {/* Preview Icon */}
            <Box component="span" sx={{ display: 'inline-block' }}>
                <TooltipWrapper tooltip="Preview">
                    <IconButton
                        onClick={() => {
                            const selectedThumbnail =
                                Array.isArray(thumbnail) && thumbnail.length > 0
                                    ? thumbnail[pageNum - 1]
                                    : null;
                            handlePreviewClick(selectedThumbnail);
                        }}
                        disabled={thumbnail ? !thumbnail?.length : !fileUrl}
                    >
                        <Preview />
                    </IconButton>
                </TooltipWrapper>
            </Box>

            {/* Modal for PDF Preview */}
            <Modal
                show={openModal}
                size="sm"
                handleClose={onClose ? onClose : handleClose}
                title={`Preview - Page ${pageNumber} of ${numPages || '...'}`}
                modalBody={
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        {/* PDF Viewer */}
                        <Grid item>
                            {fileUrl || (previewImage && pdfFile) ? (
                                <Document
                                    file={
                                        pdfFile
                                            ? generateFileUrl(pdfFile)
                                            : fileUrl
                                    }
                                    onLoadSuccess={({ numPages }) =>
                                        setNumPages(numPages)
                                    }
                                >
                                    <Page
                                        pageNumber={pageNumber}
                                        width={viewportWidth}
                                        scale={1}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                    />
                                </Document>
                            ) : (
                                <p>Loading preview...</p>
                            )}
                        </Grid>
                        {/* Navigation Buttons */}
                        <Grid item>
                            <IconButton
                                onClick={() =>
                                    setPageNumber((prev) =>
                                        Math.max(prev - 1, 1),
                                    )
                                }
                                disabled={pageNumber <= 1}
                                color="primary"
                            >
                                <ArrowBackRounded />
                            </IconButton>
                            <IconButton
                                onClick={() =>
                                    setPageNumber((prev) =>
                                        Math.min(prev + 1, numPages),
                                    )
                                }
                                disabled={pageNumber >= numPages}
                                color="primary"
                            >
                                <ArrowForwardRounded />
                            </IconButton>
                        </Grid>
                    </Grid>
                }
                headerBtns={headerBtns}
            />
        </>
    );
};

export default FilePreviewModal;
