import React, { useContext, useEffect, useState } from 'react';

import {
    Box,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';

import Modal from '../modal/Modal';
import { useCoverSheetModal } from '../../../context/CoverSheetContext';
import { UserSettingsContext } from '../../../context/UserSettingsContext';
import { userAxios } from '../../../utils/api';
import { handlePrintModal, logger } from '../../../utils/SiteHelpers';
import FilePreviewModal from './FilePreviewModal';
import { Download, Print } from '@mui/icons-material';
import { useAuth } from '../../../context/AuthContext';

const CoverSheetModal = () => {
    const {
        coverSheetOptions,
        setCoverSheetOptions,
        modalState,
        closeCoverSheetModal,
        fileUrl,
        setFileUrl,
    } = useCoverSheetModal();
    const { userSettings } = useContext(UserSettingsContext);

    const { show, onSubmit, action } = modalState;
    const { userId, accessToken } = useAuth();
    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const [pendingPrint, setPendingPrint] = useState(false);

    const handleSubmitModal = () => {
        closeCoverSheetModal();
        updateCoverSheet();

        if (action === 'print') {
            setPendingPrint(true); // Set pending print state
            onSubmit(coverSheetOptions, true); // Ensures fileUrl gets generated
        } else {
            onSubmit(coverSheetOptions); // Normal PDF generation
        }
    };

    // Watch for fileUrl change and trigger print when available
    useEffect(() => {
        if (pendingPrint && fileUrl) {
            handlePrintModal(fileUrl, setShowPreviewModal).then(() => {
                setFileUrl('');
                setShowPreviewModal(false);
            });

            setPendingPrint(false);
        }
    }, [fileUrl, pendingPrint, setFileUrl]);

    useEffect(() => {
        if (fileUrl !== '') {
            setShowPreviewModal(true);
        }
    }, [fileUrl]);

    const handlePreviewModal = () => {
        updateCoverSheet();
        onSubmit(coverSheetOptions, true);
    };

    const updateCoverSheet = async () => {
        try {
            if (!accessToken || !userId) {
                logger.error('Missing accessToken or userId in authData.');
                return;
            }

            const apiData = {
                user_id: userId,
                data: coverSheetOptions,
            };
            const customHeaders = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            };
            await userAxios
                .post(`coversheet/create-or-update`, apiData, {
                    headers: customHeaders,
                })
                .then(() => {
                    logger.info('Cover sheet updated successfully');
                })
                .catch((errors) => {
                    const apiErrors = errors.response.data.data;
                    logger.error('Post request failed:', errors);
                })
                .finally(() => {});
        } catch (error) {
            logger.error('Error updating user settings:', error);
        }
    };

    const handlePrint = () => {
        handlePrintModal(fileUrl, setShowPreviewModal);
        setShowPreviewModal(false);
        closeCoverSheetModal();
    };

    useEffect(() => {
        if (
            userSettings?.cover_sheet &&
            typeof userSettings.cover_sheet === 'object'
        ) {
            setCoverSheetOptions(userSettings.cover_sheet);
        }
    }, [userSettings]);

    if (!show) return null; // Prevent rendering if modal is not visible
    return (
        <>
            <Modal
                show={show}
                handleClose={closeCoverSheetModal}
                size="xs"
                title="Coversheet Options"
                modalBody={
                    <Box>
                        <Typography fontWeight="bold" variant="body2">
                            Include Coversheet
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                value={coverSheetOptions.includeCoverSheet}
                                onChange={(e) =>
                                    setCoverSheetOptions((prev) => ({
                                        ...prev,
                                        includeCoverSheet:
                                            e.target.value === 'true',
                                    }))
                                }
                            >
                                <MenuItem value="false">No</MenuItem>
                                <MenuItem value="true">Yes</MenuItem>
                            </Select>
                        </FormControl>

                        {coverSheetOptions.includeCoverSheet && (
                            <>
                                <Box sx={{ mt: 2 }}>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        Line 1
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={coverSheetOptions.line1}
                                        onChange={(e) =>
                                            setCoverSheetOptions((prev) => ({
                                                ...prev,
                                                line1: e.target.value,
                                            }))
                                        }
                                        placeholder="Enter Text, such as Client Name"
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        Line 2
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={coverSheetOptions.line2}
                                        onChange={(e) =>
                                            setCoverSheetOptions((prev) => ({
                                                ...prev,
                                                line2: e.target.value,
                                            }))
                                        }
                                        placeholder="Enter Text, such as Case Name"
                                        variant="outlined"
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                }
                footerButtons={[
                    {
                        label: 'Preview',
                        onClick: handlePreviewModal, // Call preview function on click
                    },
                    {
                        label: 'Submit',
                        onClick: handleSubmitModal,
                    },
                    {
                        label: 'Cancel',
                        onClick: closeCoverSheetModal,
                        variant: 'dark',
                    },
                ]}
            />
            {showPreviewModal && (
                <FilePreviewModal
                    fileUrl={fileUrl} // Pass fileUrl prop
                    openByDefault={true}
                    onClose={() => setShowPreviewModal(false)}
                    headerBtns={
                        action === 'print' // Check if action is 'print'
                            ? [
                                  {
                                      index: 1,
                                      buttonName: 'Download',
                                      onClickEvent: handleSubmitModal,
                                      icon: <Download />,
                                  },
                                  {
                                      index: 2,
                                      buttonName: 'Print',
                                      onClickEvent: handlePrint,
                                      icon: <Print />,
                                  },
                              ]
                            : [
                                  // If not, show only one button based on the action
                                  {
                                      index: 1,
                                      buttonName: 'Download',
                                      onClickEvent: handleSubmitModal,
                                      icon: <Download />,
                                  },
                              ]
                    }
                />
            )}
        </>
    );
};

export default CoverSheetModal;
