import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Typography, Button, Stack, Link } from '@mui/material';
import { SiteSettingContext } from '../context/SiteSettingContext';

const ToolkitUninstalled = () => {
    const [favIcon, setFavIcon] = useState();
    const { siteSettings } = useContext(SiteSettingContext);

    const getSiteSetting = async () => {
        setFavIcon(
            siteSettings.url +
                '/images/header/' +
                siteSettings.siteSetting.public_fav_icon,
        );
    };

    useEffect(() => {
        getSiteSetting();
    }, []);

    const buttons = [
        {
            label: 'Provide Feedback',
            href: 'https://www.toolkit.law/about?accordion=contact-us',
        },
        {
            label: 'Share Toolkit.law',
            href: 'https://www.toolkit.law/about?accordion=share-us',
        },
        {
            label: 'Visit Toolkit.law',
            href: 'https://toolkit.law',
        },
        {
            label: 'Install Toolkit.law Assistant',
            href: 'https://toolkit.law/tools/appstore',
        },
    ];

    return (
        <>
            <Helmet>
                <title>Toolkit.law Uninstalled</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                {favIcon && (
                    <link
                        rel="icon"
                        type="image/png"
                        href={favIcon}
                        sizes="16x16"
                    />
                )}
            </Helmet>

            <Box
                sx={{
                    fontFamily: 'Arial, sans-serif !important',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    backgroundColor: '#f9f9f9',
                    color: '#333',
                    px: 2,
                    minHeight: '100vh', // Allows content to expand
                    width: '100%',
                    position: 'relative',
                    backgroundImage: 'url(/assets/images/background.svg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontFamily: 'Arial, sans-serif !important',
                        fontSize: { xs: '2em', sm: '2.5em' }, // Responsive font size
                        fontWeight: 'bold',
                        textAlign: 'center',
                        mb: 3,
                        mt: 4, // Adjusted for spacing
                        position: 'relative', // No absolute positioning
                    }}
                >
                    Toolkit.law Assistant Uninstalled
                </Typography>

                <Stack
                    spacing={2}
                    sx={{
                        width: '100%',
                        maxWidth: '500px',
                        mx: 'auto',
                        my: ' 1.5rem',
                    }}
                >
                    {buttons
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((button, index) => (
                            <Button
                                key={index}
                                href={button.href}
                                variant="contained"
                                sx={{
                                    padding: '10px 20px',
                                    color: 'black',
                                    fontSize: { xs: '1.2rem', sm: '1.5rem' }, // Responsive font size
                                    width: '100%', // Full width for responsiveness
                                    maxWidth: '100%', // Prevent overflow
                                    '&:hover': {
                                        color: 'black',
                                    },
                                    textTransform: 'none',
                                }}
                            >
                                {button.label}
                            </Button>
                        ))}
                </Stack>

                <Box
                    component={Link}
                    href="https://www.toolkit.law"
                    rel="noopener noreferrer"
                    sx={{
                        position: 'relative',
                        mt: 4, // Spacing from buttons
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        component={'img'}
                        src={
                            'https://admin.toolkit.law/storage/banner_images/home_section_ads/adImage1727366136_0_desktop.svg'
                        }
                        sx={{
                            maxWidth: '90%', // Adjust width for responsiveness
                            height: 'auto', // Maintain aspect ratio
                            display: 'block', // Prevent inline spacing issues
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default ToolkitUninstalled;
