import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { BlogContext } from '../../context/BlogContext';
import BlogCard from './BlogCard';
import BlogBreadcrumbs from './BlogBreadcrumbs';
import { capitalize } from '../../utils/Utils';
import { SiteSettingContext } from '../../context/SiteSettingContext';
import SEO from '../../components/reusable/SEO';
import { config } from '../../config/config';

const CategoryList = () => {
    const { category } = useParams(); // Get category from URL params
    const { siteSettings } = useContext(SiteSettingContext);
    const { blogImageUrl } = config;
    const { blogs } = useContext(BlogContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const favIcon =
        siteSettings.url +
        '/images/header/' +
        siteSettings?.siteSetting?.public_fav_icon;

    // Filter blogs based on category
    const filteredBlogs = blogs.filter(
        (blog) => blog.category?.name === capitalize(category),
    );

    const categoryBanner =
        filteredBlogs.length > 0
            ? filteredBlogs[0].category.category_banner
            : null;
    const categoryBannerUrl =
        filteredBlogs.length > 0
            ? filteredBlogs[0].category.category_banner_link
            : null;

    console.log(categoryBanner);
    console.log(filteredBlogs);
    return (
        <Box sx={{ p: 4, mt: 6, mb: 2 }}>
            <SEO
                pageTitle={`Blogs | Toolkit.law`}
                description={`Toolkit.law Blogs`}
                keywords={`Blogs for Legal tools, Blogs, Legal tools`}
                favIcon={favIcon}
            />
            <Typography
                variant="h4"
                component="h1"
                gutterBottom
                textAlign={'center'}
                sx={{
                    fontSize: isMobile ? '1.5rem' : '2rem',
                    fontWeight: '500',
                }}
            >
                {capitalize(category)} Blogs
            </Typography>
            <Grid container spacing={3} mb={6}>
                <BlogBreadcrumbs />
                {filteredBlogs.length > 0 ? (
                    filteredBlogs.map((blog) => (
                        <Grid item xs={12} sm={6} md={4} key={blog.slug}>
                            <BlogCard blog={blog} />
                        </Grid>
                    ))
                ) : (
                    <Typography variant="body1">
                        No blogs available in this category.
                    </Typography>
                )}
                {categoryBanner && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            component={'img'}
                            src={`${blogImageUrl}category/${categoryBanner}`}
                            href={categoryBannerUrl}
                            sx={{
                                width: isMobile ? '300px' : 'auto',
                                height: isMobile ? '60px' : 'auto',
                                mt: 2,
                            }}
                        />
                    </Box>
                )}
            </Grid>
        </Box>
    );
};

export default CategoryList;
