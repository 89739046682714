import { createContext, useContext, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { getAuthData } from '../utils/auth';
import { logger } from '../utils/SiteHelpers';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const [authState, setAuthState] = useState({
        accessToken: null,
        userId: null,
    });

    useEffect(() => {
        const fetchAuthData = async () => {
            if (isAuthenticated && user) {
                try {
                    const { email, sub } = user;
                    const authData = await getAuthData(email, sub);

                    if (authData) {
                        setAuthState({
                            accessToken: authData.accessToken,
                            userId: authData.userId,
                        });
                    }
                } catch (error) {
                    logger.error('Error fetching auth data:', error);
                }
            }
        };

        fetchAuthData();
    }, [isAuthenticated, user]);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                loginWithRedirect,
                logout,
                ...authState,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
