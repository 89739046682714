import { createContext, useContext, useState } from 'react';

const CoverSheetContext = createContext();

export const useCoverSheetModal = () => {
    return useContext(CoverSheetContext);
};

export const CoverSheetModalProvider = ({ children }) => {
    const defaultCoverSheetOptions = {
        includeCoverSheet: true,
        line1: '',
        line2: '',
    };
    const [coverSheetOptions, setCoverSheetOptions] = useState(
        defaultCoverSheetOptions,
    );
    const [modalState, setModalState] = useState({
        show: false,
        action: null,
        onSubmit: null,
    });
    const [fileUrl, setFileUrl] = useState('');

    const openCoverSheetModal = (action, onSubmit) => {
        setModalState({
            show: true,
            action,
            onSubmit,
        });
    };

    const closeCoverSheetModal = () => {
        setModalState({ show: false, onSubmit: null });
    };

    const value = {
        modalState,
        defaultCoverSheetOptions,
        coverSheetOptions,
        setCoverSheetOptions,
        openCoverSheetModal,
        closeCoverSheetModal,
        setFileUrl,
        fileUrl,
    };

    return (
        <CoverSheetContext.Provider value={value}>
            {children}
        </CoverSheetContext.Provider>
    );
};
