import React from 'react';

import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Link,
    Box,
} from '@mui/material';

import PDFViewer from '../../components/reusable/PDFViewer';
import { ExternalLink } from '../../utils/SiteHelpers';
import MarketingVideo from './MarketingVideo';
import useHandleAuthenticatedLink from '../../components/content/webnars/useHandleAuthenticatedLink';
import { config } from '../../config/config';
import { useAuth } from '../../context/AuthContext';

const { siteUrl } = config;

export const SignUpLink = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth();

    if (isAuthenticated) return;

    return (
        <Link
            component="button"
            color="primary.main"
            onClick={() =>
                loginWithRedirect({
                    authorizationParams: { screen_hint: 'signup' },
                })
            }
        >
            Sign up now!
        </Link>
    );
};

const accountDetails = [
    {
        title: 'Free Six-Month Trial Account:',
        description:
            'Toolkit.law offers a Free Six-Month Trial Account with full access to our site, marketplace, and CLE.',
    },
    {
        title: 'Annual Accounts:',
        description:
            'Toolkit.law is $100 (plus processing fees) after your free trial period ends.',
    },
    {
        title: 'Free Bar Organization Employee Accounts:',
        description: (
            <Box component="span">
                Toolkit.law is free to employees of{' '}
                <ExternalLink
                    text="bar organizations"
                    href={`${siteUrl}/about?accordion=bar-organizations`}
                    newTab={false}
                />{' '}
                that approve our free, global{' '}
                <ExternalLink
                    text="CLE Webinars"
                    href={`${siteUrl}/webinars`}
                    newTab={false}
                />
                . These accounts provide full access to everything available to
                Annual Account users.
            </Box>
        ),
    },
    {
        title: 'Free Educational & .EDU Accounts:',
        description: (
            <Box component="span">
                Toolkit.law is free to{' '}
                <ExternalLink
                    text="law school users"
                    href={`${siteUrl}/about?accordion=law-schoolsedu`}
                    newTab={false}
                />{' '}
                and those with .EDU email addresses. These accounts provide full
                access to everything available to Annual Account users.
            </Box>
        ),
    },
    {
        title: 'Free Judicial Staff Accounts:',
        description: (
            <Box component="span">
                Toolkit.law is free to{' '}
                <ExternalLink
                    text="judges"
                    href={`${siteUrl}/about?accordion=judges-judicial-staff`}
                    newTab={false}
                />{' '}
                and their immediate courtroom staff using their official
                judicial email accounts. these accounts provide full access to
                everything available to Annual Account users.
            </Box>
        ),
    },
    {
        title: 'Free US Military & .MIL Accounts:',
        description: (
            <Box component="span">
                Toolkit.law is free to members of the{' '}
                <ExternalLink
                    text="United States Military"
                    href={`${siteUrl}/about?accordion=us-militarymil`}
                    newTab={false}
                />{' '}
                with .MIL or other official US Military email addresses. These
                accounts provide full access to everything available to Annual
                Account users.
            </Box>
        ),
    },
];

const AccountTypes = (props) => {
    const { isNested = true } = props;
    const handleClick = useHandleAuthenticatedLink();

    return (
        <Box>
            <Typography variant="body1" paragraph>
                Toolkit.law offers 1,500+ original legal tools, calculators, and
                utilities. We include a legal marketplace to sell your services
                and your own user webstore page from which to run your business.
                Plus, we include a 24-hour online continuing legal education
                (CLE) webinar. Sign up for one of our{' '}
                {!isNested ? (
                    <ExternalLink
                        href={`/about?accordion=account-plans`}
                        text="Account Plans"
                        newTab={false}
                    />
                ) : (
                    'Account Plans'
                )}{' '}
                including our free 6-month Trial Account.
            </Typography>
            <List sx={{ marginLeft: '40px' }}>
                {accountDetails.map((account, index) => (
                    <ListItem key={index} sx={{ mb: 2 }}>
                        <ListItemText
                            primary={
                                <Typography component="div">
                                    <Box fontWeight="bold" display="inline">
                                        {account.title}
                                    </Box>{' '}
                                    <Box component="span" display="inline">
                                        {account.description}{' '}
                                    </Box>
                                    <SignUpLink />
                                </Typography>
                            }
                        />
                    </ListItem>
                ))}
            </List>
            <MarketingVideo
                videoId="1DNVem8uF50cu7QDwmoiUEg1eCyxuUnai"
                thumbnailId="1iEeMT8Fw5EqwsneTOEs7F2-swhqjLl58"
            />
            <PDFViewer flyerId="1S_pnDGh0Bp9eyCiDrjx2xprYx5LlLAJm" />
        </Box>
    );
};

export default AccountTypes;
