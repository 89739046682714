import { createContext, useContext, useEffect, useState } from 'react';

import { useChatAxios, userAxios, useSocket } from '../utils/api';
import {
    // defaultLeftHeaderContent
    generateDefaultLeftHeadersEmpty,
} from '../components/settings/headerLeft/defaultLeftHeaders';
import { LoaderContext } from './LoaderContext';
import { AlertMessageContext } from './AlertMessageContext';
import { logger } from '../utils/SiteHelpers';
import { useAuth } from './AuthContext';

export const UserSettingsContext = createContext({
    userSettings: false,
    setUserSettings: () => {},
    adminSiteUrl: '',
});

export const UserSettingsProvider = ({ children }) => {
    // const defaultSettings = {
    //     header_left_toolbar: generateDefaultLeftHeadersEmpty(),
    // };

    const [userSettings, setUserSettings] = useState({
        header_left_toolbar: generateDefaultLeftHeadersEmpty(),
        user_slug: '',
        user_id: null,
        trial: null,
        prof_company_address: '',
        social_media: '',
        background_options: '',
    });
    const [chatterId, setChatterId] = useState(null);
    const [adminSiteUrl, setAdminSiteUrl] = useState('');
    const { setSuccessMessage, setErrorMessage } =
        useContext(AlertMessageContext);
    const { setShowLoader, setLoaderMessage } = useContext(LoaderContext);
    const { user, loginWithRedirect, userId, accessToken } = useAuth();
    const {
        response: chatterNotifications,
        setResponse: setChatterNotifications,
        axiosFetch: fetchChatterNotifications,
        loading: loadingChatterNotifications,
    } = useChatAxios();

    const { socket, onEvent, emitEvent } = useSocket({ userId: chatterId }); // chatter websocket

    useEffect(() => {
        // Call the function to fetch user notes
        fetchUserSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]); // <-- Empty dependency array, runs only once

    // Function to fetch user notes from the backend API
    const fetchUserSettings = async () => {
        try {
            if (!user) {
                return; // Login Again
            }

            if (userId && accessToken) {
                const customHeaders = {
                    Authorization: `Bearer ${accessToken}`,
                };

                const response = await userAxios.get(
                    `user-settings?userId=${userId}`,
                    { headers: customHeaders },
                );

                if (response.status === 200) {
                    const {
                        data: { data },
                    } = response;
                    if (data && data.length > 0) {
                        const settings = {
                            // extract only required settings to improve performance
                            header_left_toolbar: data[0]?.header_left_toolbar,
                            user_slug: data[0].user_slug,
                            user_id: data[0].user_id,
                            trial: data[0].trial,
                            social_media: [
                                {
                                    name: 'facebook',
                                    value: data[0].facebook_url,
                                },
                                {
                                    name: 'instagram',
                                    value: data[0].instagram_url,
                                },
                                {
                                    name: 'linkedin',
                                    value: data[0].linkedin_url,
                                },
                                {
                                    name: 'whatsapp',
                                    value: data[0].whatsapp_business_url,
                                },
                                { name: 'twitter', value: data[0].twitter_url },
                                { name: 'youtube', value: data[0].youtube_url },
                            ],
                            cover_sheet: data[0].cover_sheet,
                            payment_method: data[0].payment_method,
                            background_options: data[0].background_options,
                        };
                        setUserSettings((prevSettings) => ({
                            ...prevSettings,
                            header_left_toolbar:
                                settings.header_left_toolbar?.length > 0
                                    ? settings.header_left_toolbar
                                    : prevSettings.header_left_toolbar,
                            user_slug: settings.user_slug,
                            user_id: settings.user_id,
                            trial: settings.trial,
                            social_media: settings.social_media,
                            cover_sheet: settings.cover_sheet,
                            payment_method: settings.payment_method,
                            background_options: settings.background_options,
                        }));
                    }
                    const chatter_id = data[0].chatter_id;
                    if (chatter_id && chatter_id !== chatterId) {
                        setChatterId(chatter_id);
                    }
                } else {
                    logger.error(
                        'Failed to fetch user notes. Status:',
                        response.status,
                    );
                    // Handle the case when the API returns a status other than 2xx (e.g., 400)
                }
            } else {
                logger.error('Missing userId or accessToken in authData.');
                // Handle the case when required properties are missing in authData
            }
        } catch (error) {
            // Handle error
            logger.error('Error fetching user notes:', error);
        }
    };

    // Function to update user settings
    const updateUserSettings = async (newSettings, formId) => {
        try {
            setUserSettings(newSettings);
            if (!user) {
                loginWithRedirect();
                return;
            }

            setShowLoader(true);
            setLoaderMessage('Saving');

            if (!accessToken || !userId) {
                logger.error('Missing accessToken or userId in authData.');
                return;
            }

            const apiData = {
                form_id: 1,
                user_id: userId,
                ...newSettings,
            };

            const customHeaders = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            };
            await userAxios
                .post(`user-settings/create-or-update`, apiData, {
                    headers: customHeaders,
                })
                .then(() => {
                    setSuccessMessage('Saving: Done');
                })
                .catch((errors) => {
                    const apiErrors = errors.response.data.data;
                    setErrorMessage('API Error: ' + JSON.stringify(apiErrors));
                    logger.error('Post request failed:', errors);
                })
                .finally(() => {
                    setShowLoader(false);
                });
        } catch (error) {
            logger.error('Error updating user settings:', error);
            setErrorMessage('Saving: Failed');
        }
    };

    useEffect(() => {
        if (chatterId && !loadingChatterNotifications) {
            fetchChatterNotifications({
                method: 'GET',
                url: `/api/user/${chatterId}/notifications`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatterId]);

    useEffect(() => {
        if (!socket || !chatterId) return;

        const handleUserNotifications = (data) => {
            const { operation, userId, roomId, type, _id } = data;
            if (operation === 'created' && userId === chatterId) {
                setChatterNotifications((prevChatterNotifications) => [
                    ...prevChatterNotifications,
                    {
                        _id,
                        roomId,
                        type,
                    },
                ]);
            } else if (operation === 'deleted' && userId === chatterId) {
                setChatterNotifications((prevChatterNotifications) =>
                    prevChatterNotifications.filter(
                        (notification) =>
                            notification.userId !== userId &&
                            notification.roomId !== roomId,
                    ),
                );
            }
        };

        onEvent('userNotifications', handleUserNotifications);

        return () => {
            socket.off('userNotifications', handleUserNotifications);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, chatterId, onEvent]);

    return (
        <UserSettingsContext.Provider
            value={{
                userSettings,
                setUserSettings,
                updateUserSettings,
                adminSiteUrl,
                setAdminSiteUrl,
                chatter: {
                    chatterId,
                    chatterNotifications,
                    setChatterNotifications,
                    socket,
                    onEvent,
                    emitEvent,
                },
            }}
        >
            {children}
        </UserSettingsContext.Provider>
    );
};
