import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { isIosSource } from '../../components/content/webnars/basicInformation/schema';
const MarketingVideo = ({ heading, videoId, thumbnailId }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        setIsPlaying(true);
    };
    const VideoUrl = `${process.env.REACT_APP_GOOGLE_DRIVE_API}${videoId}`;
    const thumbnailURL = `${process.env.REACT_APP_GOOGLE_DRIVE_API}${thumbnailId}`;

    const show_video_ids = [
        '1VXYwzTqnvsuFhGluYIsUi20_VeYaEO6T',
        '1VZxqY-brYSU7cFtWqon3Mv-cOdITKTL8',
        '1EiiugOPkG-rEXD1iuBSXjM3gYFuUTKjH',
    ];

    if (isIosSource && !show_video_ids.includes(videoId)) {
        return null;
    }
    return (
        <Box>
            {heading && (
                <Typography variant="h5" fontWeight="bold" align="center">
                    {heading}
                </Typography>
            )}

            <Box
                sx={{
                    width: { xs: '100%', sm: '75%', md: '60%' },
                    height: 'auto',
                    // maxHeight: '350px',
                    maxWidth: '800px',
                    aspectRatio: '16/9',
                    border: '1px solid',
                    display: 'block',
                    margin: '0 auto',
                    marginTop: '1rem',
                    mb: 4,
                    position: 'relative',
                }}
            >
                <ReactPlayer
                    url={VideoUrl}
                    light={thumbnailURL}
                    playing={isPlaying}
                    controls={true}
                    onClickPreview={handlePlay}
                    width="100%"
                    height="100%"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
            </Box>
        </Box>
    );
};

export default MarketingVideo;
