import {
    Card,
    CardContent,
    Typography,
    CardMedia,
    Button,
    Box,
    Chip,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom'; // Assuming you'll use React Router
import parse from 'html-react-parser';
import { config } from '../../config/config';
import { formatDate } from '../../utils/SiteHelpers';

const BlogCard = ({ blog }) => {
    const { blogImageUrl } = config;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Link to={`/blog/${blog.slug}`}>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    position: 'relative',
                    border: '1px solid #ddd',
                }}
            >
                <Chip
                    label={blog.category.name}
                    color="primary"
                    sx={{ position: 'absolute', top: 16, right: 16 }}
                />

                <CardMedia
                    component="img"
                    height="200"
                    image={`${blogImageUrl}${
                        blog.featured_image ? blog.featured_image : blog.image
                    }`}
                    alt={blog.title}
                    sx={{ objectFit: isMobile ? 'cover' : 'fill' }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                    >
                        {formatDate(blog.created_at)}
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        {blog.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {parse(blog.description)}
                    </Typography>
                </CardContent>
                <Box mt="auto" p={2}>
                    <Link to={`/blog/${blog.slug}`}>
                        <Button variant="contained" color="primary">
                            Read More
                        </Button>
                    </Link>
                </Box>
            </Card>
        </Link>
    );
};

export default BlogCard;
