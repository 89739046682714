import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    List,
    ListItem,
    Typography,
} from '@mui/material';
import Data from '../../../reusable/CustomContent';
import React, { useState } from 'react';
import PDFViewer from '../../../reusable/PDFViewer';
import { ClearRounded } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { ExternalLink } from '../../../../utils/SiteHelpers';
const linkUrl = process.env.REACT_APP_SITE_URL;

export const ListItems = ({
    items,
    defaultUrl,
    newTab = true,
    isModal = false,
    sortByNumber = false,
    isSort = true,
}) => {
    const getNumber = (item) => {
        const match = (item.name || item.title).match(/\d+/);
        return match ? parseInt(match[0], 10) : 0;
    };

    const sortedItems =
        isSort === false
            ? items // Don't sort if noSort is true
            : [...items].sort((a, b) => {
                  if (sortByNumber) {
                      // Sort by extracted number
                      return getNumber(a) - getNumber(b);
                  } else {
                      // Sort alphabetically, fallback to empty string if name or title is missing
                      return (a.name || a.title || '').localeCompare(
                          b.name || b.title || '',
                      );
                  }
              });

    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const handleClose = () => setOpen(false);
    const handleOpen = (url) => {
        setModalContent(url);
        setOpen(true);
    };

    const renderIcon = (icon) => {
        if (typeof icon === 'string') {
            // Assume it's a URL if it's a string
            return (
                <img
                    src={icon}
                    alt="icon"
                    style={{
                        width: 24,
                        height: 24,
                        marginRight: 14,
                    }}
                />
            );
        } else if (React.isValidElement(icon)) {
            // Render the MUI icon component if it's a valid React element
            return React.cloneElement(icon, {
                style: {
                    width: 22,
                    height: 22,
                    marginRight: 14,
                    ...icon.props.style, // Preserve any existing styles
                },
            });
        }
        return null;
    };

    return (
        <Box>
            <List sx={{ paddingLeft: '2.5rem' }}>
                {sortedItems.map((item, index) => (
                    <ListItem
                        key={index}
                        sx={{
                            listStyleType: item.icon ? 'none' : 'disc',
                            marginTop: item.icon ? 1 : 0,
                        }}
                    >
                        {isModal ? (
                            <Link
                                onClick={(e) => {
                                    if (isModal) {
                                        e.preventDefault();
                                        handleOpen(item.url || defaultUrl);
                                    }
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {renderIcon(item.icon)}
                                {item.name || item.title}
                            </Link>
                        ) : item.url || defaultUrl ? (
                            newTab ? (
                                <Link
                                    href={item.url || defaultUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    {renderIcon(item.icon)}
                                    {item.name || item.title}
                                </Link>
                            ) : (
                                <RouterLink
                                    to={item.url || defaultUrl}
                                    onClick={() => window.scrollTo(0, 0)}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    {renderIcon(item.icon)}
                                    {item.name || item.title}
                                </RouterLink>
                            )
                        ) : (
                            <>
                                {renderIcon(item.icon)}
                                {item.name || item.title}
                            </>
                        )}
                    </ListItem>
                ))}
            </List>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between">
                        PDF Viewer
                        <IconButton onClick={handleClose}>
                            <ClearRounded />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ padding: '16px 15px' }}>
                    <PDFViewer
                        fileUrl={modalContent}
                        width="100%"
                        pageSize="ActualSize"
                        height="70vh"
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export const alcoholDetails = [
    {
        children: (
            <Data
                heading="Have a Problem with Alcohol? "
                paragraph="Founded in 1935, Alcoholics Anonymous (AA) is a global, peer-led mutual-aid fellowship supporting abstinence-based recovery from alcoholism through its spiritually inclined twelve-step program. "
            />
        ),
    },
    {
        children: (
            <Data
                heading="There is a Solution"
                paragraph={
                    <Box>
                        AA’s Twelve Traditions, besides stressing anonymity and
                        the lack of a governing hierarchy, establish AA as free
                        to all, non-professional, unaffiliated, and
                        non-denominational, as well as apolitical. For more
                        information, see{' '}
                        <Link
                            href="http://www.aa.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            AA.org
                        </Link>
                        .
                    </Box>
                }
            />
        ),
    },
    {
        children: (
            <Data
                heading="The AA Program "
                paragraph={
                    <Box>
                        AA's program extends beyond abstaining from alcohol. Its
                        goal is to effect enough change in the alcoholic's
                        thinking "to bring about recovery from alcoholism"
                        through "an entire psychic change," or spiritual
                        awakening.
                    </Box>
                }
            />
        ),
    },
    {
        children: (
            <Data
                paragraph={
                    <Box>
                        A spiritual awakening is meant to be achieved by taking
                        the Twelve Steps, and sobriety is furthered by
                        volunteering for AA and regular AA meeting attendance or
                        contact with AA members. For more information on “The AA
                        Program”, see{' '}
                        <Link
                            href="http://www.aa.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            AA.org
                        </Link>
                        .
                    </Box>
                }
            />
        ),
    },
    {
        children:(
            <Data
            paragraph={
                <Box>
                   Alcoholics Anonymous (AA) is a global, peer-led mutual-aid fellowship focused on an abstinence-based recovery model from alcoholism through its spiritually inclined twelve-step program.[1] AA’s Twelve Traditions, besides emphasizing anonymity, stress lack of hierarchy, staying non-promotional, and non-professional, while also unaffiliated, non-denominational, apolitical and free to all.{"  "}<ExternalLink text={'Click here'} href={`${linkUrl}/user/nsc`}/> for alcohol abuse testing
                   
                   
                    .
                </Box>
            }
        />
                
        )
    }
];

const angerManagement = [
    { name: 'Anger Class', url: 'http://www.angerclass.com' },
    { name: 'AngerMasters', url: 'http://www.angermasters.com' },
    { name: 'BetterHelp', url: 'http://www.betterhelp.com' },
    { name: 'Course for Anger', url: 'http://www.courseforanger.com' },
    { name: 'Mastering Anger', url: 'http://www.masteringanger.com' },
];
export const angerManagementDetails = [
    {
        children: (
            <Data
                heading="What Are Anger Management Programs?"
                paragraph="Anger management programs are a type of therapy that helps people learn to manage their anger and stress. They can include one-on-one sessions or group counseling and may use a combination of counseling and cognitive behavioral therapy (CBT) techniques."
            />
        ),
    },
    {
        children: (
            <Data
                heading="Which Programs Are Most Effective?"
                paragraph="  Anger management programs may be offered as online therapy, which can be convenient and save time and money. However, in-person therapy may be better for anger related to complex mental health issues."
            />
        ),
    },
    {
        children: (
            <Data paragraph="Anger management classes can range from 8 to 28 sessions, usually one to two hours long and held weekly. If this is for a court case, be sure your court accepts a program’s certificate of completion." />
        ),
    },
    {
        children: (
            <Data
                heading="Find An Anger Management Program"
                paragraph={
                    <Box>
                        Here are some programs you can explore:
                        <ListItems items={angerManagement} />
                    </Box>
                }
            />
        ),
    },
];

const violenceLinks = [
    { name: 'Course for Violence', url: 'http://www.courseforviolence.com' },
    { name: 'Stop DV Class', url: 'http://www.stopdvclass.com' },
];

export const domesticViolenceDetails = [
    {
        children: (
            <Data
                heading="What is Domestic Violence?"
                paragraph="Domestic Violence (DV) is violence committed by someone in the victim's domestic circle. This includes partners and ex-partners, immediate family members, other relatives, and family friends. The term 'domestic violence' is used when there is a close relationship between the offender and the victim."
            />
        ),
    },
    {
        children: (
            <Data
                heading="Domestic Violence Programs"
                paragraph={
                    <Box>
                        Here are some courses to address domestic violence. In
                        some cultures, these might be referred to as "batterer
                        intervention programs":
                        <ListItems items={violenceLinks} />
                    </Box>
                }
            />
        ),
    },
];

const lsatPrograms = [
    { name: 'Barbri', url: 'https://www.barbri.com/' },
    { name: 'Blueprint Prep', url: 'https://blueprintprep.com/' },
    { name: 'Kaplan', url: 'http://www.kaptest.com' },
    { name: 'Kahn Academy', url: 'http://www.khanacademy.org' },
    { name: 'LSATMax by TestMaxPrep', url: 'http://www.lsatmax.com' },
    { name: 'Magoosh', url: 'http://www.magoosh.com' },
    { name: 'PowerScore by Barbri', url: 'http://www.powerscore.com' },
    { name: 'Princeton Review', url: 'http://www.princetonreview.com' },
    { name: 'Test Masters', url: 'https://www.testmasters.com/' },
    { name: 'TexsMaxPrep', url: 'https://testmaxprep.com' },
];

const lawSchoolSuccessPrograms = [
    { name: 'Barbri', url: 'https://www.barbri.com' },
    { name: 'Kaplan', url: 'http://www.kaptest.com' },
    { name: 'Paralegal.edu', url: 'http://www.paralegal.edu' },
    { name: 'PowerScore by Barbri', url: 'http://www.powerscore.com' },
    { name: 'TestMaxPrep', url: 'https://testmaxprep.com' },
];

const barExamPrograms = [
    { name: 'Barbri', url: 'https://www.barbri.com/' },
    { name: 'Blueprint Prep', url: 'http://www.blueprintprep.com' },
    { name: 'Kaplan', url: 'http://www.kaptest.com' },
    { name: 'Kahn Academy', url: 'http://www.khanacademy.org' },
    { name: 'LSATMax by TestMaxPrep', url: 'http://www.lsatmax.com' },
    { name: 'Magoosh', url: 'http://www.magoosh.com' },
    { name: 'PowerScore by Barbri', url: 'http://www.powerscore.com' },
    { name: 'Princeton Review', url: 'http://www.princetonreview.com' },
    { name: 'Test Masters', url: 'https://www.testmasters.com/' },
    { name: 'TexsMaxPrep', url: 'https://testmaxprep.com' },
];

export const lawSchoolDetails = [
    {
        children: (
            <Data
                heading="Law School Admissions Test Preparation"
                paragraph={
                    <Box>
                        Many jurisdictions require a law school admission test
                        (LSAT) as part of the application process. It is highly
                        recommended to use an LSAT preparation course to achieve
                        the highest grade. Consider these programs:
                        <ListItems items={lsatPrograms} />
                    </Box>
                }
            />
        ),
    },
    {
        children: (
            <Data
                heading="Law School Success"
                paragraph={
                    <Box>
                        There are law school preparation and supplemental
                        training courses offered to help achieve the greatest
                        success in law school. Consider these programs:
                        <ListItems items={lawSchoolSuccessPrograms} />
                    </Box>
                }
            />
        ),
    },
    {
        children: (
            <Data
                heading="Bar Exam Preparation"
                paragraph={
                    <Box>
                        Passing your local bar association or bar council’s
                        jurisdictional examination to be licensed as an attorney
                        is often a “life event” in and of itself. It is highly
                        recommended to take a bar examination preparation
                        course. Consider these programs:
                        <ListItems items={barExamPrograms} />
                    </Box>
                }
            />
        ),
    },
];

export const liveChallengesDetails = [
    {
        children: (
            <Data
                heading="What is CR?"
                paragraph={
                    <Box>
                        Celebrate Recovery is a free recovery program aimed at
                        all "hurts, habits, and hang-ups", including but not
                        exclusive to: high anxiety; co-dependency; compulsive
                        behaviors; sex addiction; financial dysfunction; drug
                        and alcohol addictions; and eating disorders. Celebrate
                        Recovery is one of the largest addiction recovery
                        support group programs in the world.
                    </Box>
                }
            />
        ),
    },
    {
        children: (
            <Data
                heading="What Does CR Address?"
                paragraph="Celebrate Recovery is a Christian-based program that helps people overcome a range of issues, including addiction, codependency, and other personal problems."
            />
        ),
    },
    {
        children: (
            <Data
                paragraph={
                    <Box>
                        The program is based on the 12 steps of Alcoholics
                        Anonymous and the Beatitudes of Jesus. Celebrate
                        Recovery is free and is now included in 35,000+ churches
                        worldwide. For more information, see{' '}
                        <Link
                            href="https://www.celebraterecovery.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            CelebrateRecovery.com
                        </Link>
                        .
                    </Box>
                }
            />
        ),
    },
];

const adrPrograms = [
    {
        name: 'ArbitrateUniversity.com',
        url: 'http://www.arbitrateuniversity.com',
    },
    {
        name: 'Coursera',
        url: 'https://www.coursera.org/courses?query=arbitration',
    },
    {
        name: 'International Mediation Campus',
        url: 'https://im-campus.com/mediation-training-online/',
    },
    {
        name: 'MediationWorks',
        url: 'http://www.mediationworks.com',
    },
    {
        name: 'MediateUniversity.com',
        url: 'http://www.mediatteuniversity.com',
    },
];

export const adrDetails = [
    {
        children: (
            <Data
                heading="What is ADR?"
                paragraph={
                    <Box>
                        Alternative dispute resolution (ADR) is a variety of
                        methods for resolving disputes without going to trial.
                        ADR processes are typically less formal, confidential,
                        and less stressful than traditional court proceedings.
                        These include arbitration, mediation, and collaborative
                        law practices.
                    </Box>
                }
            />
        ),
    },
    {
        children: (
            <Data
                heading="ADR Training & Certification"
                paragraph={
                    <Box>
                        Consider the following ADR certification training
                        programs:
                        <ListItems items={adrPrograms} />
                    </Box>
                }
            />
        ),
    },
];

const marketingCourses = [
    {
        name: 'Udemy Affiliate Marketing',
        url: 'https://www.udemy.com/courses/marketing/affiliate-marketing/',
    },
    {
        name: 'Udemy Content Creation',
        url: 'https://www.udemy.com/topic/content-creation/',
    },
    {
        name: 'Udemy Digital Marketing',
        url: 'https://www.udemy.com/courses/marketing/digital-marketing/',
    },
    {
        name: 'Udemy Google Analytics',
        url: 'https://www.udemy.com/topic/google-analytics/',
    },
    {
        name: 'Udemy Marketing Analytics & Automation Courses',
        url: 'https://www.udemy.com/courses/marketing/analytics-and-automation/',
    },
    {
        name: 'Udemy Search Engine Optimization (SEO)',
        url: 'https://www.udemy.com/courses/marketing/search-engine-optimization/',
    },
    {
        name: 'Udemy Social Media Marketing (SMM)',
        url: 'https://www.udemy.com/courses/marketing/social-media-marketing/',
    },
    {
        name: 'Udemy Video & Mobile Marketing',
        url: 'https://www.udemy.com/courses/marketing/video-and-mobile-marketing/',
    },
    {
        name: 'Udemy YouTube Marketing',
        url: 'https://www.udemy.com/topic/youtube-marketing/',
    },
];

export const marketingDetails = [
    {
        children: (
            <Data
                heading="Modern Marketing Training Courses"
                paragraph={
                    <Box>
                        Consider the following modern marketing training courses
                        and certifications to help your modern legal career or
                        law-related business:
                        <ListItems items={marketingCourses} />
                    </Box>
                }
            />
        ),
    },
];

export const narcoticsDetails = [
    {
        children: (
            <Data
                heading="What is NA?"
                paragraph="Narcotics Anonymous (NA), founded in 1953, is a 'nonprofit fellowship or society of men and women for whom drugs had become a major problem.' Narcotics Anonymous uses a 12-step model developed for people with varied substance use disorders and is the second-largest 12-step organization, after Alcoholics Anonymous."
            />
        ),
    },
    {
        children: (
            <Data
                heading="NA Meetings"
                paragraph=" Regular meetings, hosted by NA groups, are the basic
                        unit of the NA fellowship. Meetings are held in various
                        locations such as church meeting rooms, libraries,
                        hospitals, community centers, parks, or any other place
                        that can accommodate a meeting."
            />
        ),
    },
    {
        children: (
            <Data
                paragraph={
                    <Box>
                        Members who attend the same meeting regularly to
                        establish a recovery network and reliable routine
                        understand this to be their "home group." Home-group
                        members can participate in the group's business and play
                        an important role in deciding how the group's meetings
                        should be conducted. For more information, see{' '}
                        <Link
                            href="https://www.na.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            NA.org
                        </Link>
                        .
                    </Box>
                }
            />
        ),
    },
];

const pornographyPrograms = [
    {
        name: 'AddictionHelp.com',
        url: 'https://www.addictionhelp.com/porn/recovery/',
    },
    { name: 'Elevated Recovery', url: 'https://www.elevatedrecovery.org' },
    { name: 'Modern Intimacy', url: 'https://www.modernintimacy.com' },
    {
        name: 'National Association for Addiction Professionals',
        url: 'https://www.naadac.org',
    },
    { name: 'Pure Life Academy', url: 'https://www.purelifeacademy.org' },
    {
        name: 'Renewed Life Coaching',
        url: 'https://www.renewedhopecoaching.com',
    },
    { name: 'The Journey Course', url: 'http://www.thejourneycourse.com' },
    {
        name: 'Udemy Porn Addiction Course',
        url: 'https://www.udemy.com/course',
    },
];
export const pornographyDetails = [
    {
        children: (
            <Data
                heading="What is Pornographic Addiction?"
                paragraph="Pornography addiction may be defined as repeatedly engaging in any of the following: Using pornographic materials, chat rooms, and sex lines in excess. Excessive masturbation. Treatment of sexual partners as objects."
            />
        ),
    },
    {
        children: (
            <Data
                heading="Addressing Pornographic Addiction"
                paragraph={
                    <Box>
                        The following programs should be considered to address
                        pornographic addiction:
                        <ListItems items={pornographyPrograms} />
                    </Box>
                }
            />
        ),
    },
];

export const substanceAbuseDetails = [
    {
        children: (
            <Data
                heading="What is Substance Abuse?"
                paragraph="Substance abuse, also known as drug abuse, is the use of a drug in amounts or by methods that are harmful to the individual or others. It is a form of substance-related disorder. Differing definitions of drug abuse are used in public health, medical, and criminal justice contexts."
            />
        ),
    },
    {
        children: (
            <Data paragraph="In some cases, criminal or anti-social behavior occurs when the person is under the influence of a drug, and long-term personality changes in individuals may also occur. In addition to possible physical, social, and psychological harm, the use of some drugs may also lead to criminal penalties, although these vary widely depending on the local jurisdiction." />
        ),
    },
    {
        children: (
            <Data
                heading="Types of Drugs Used"
                paragraph=" Drugs most often associated with this term include
                        alcohol, amphetamines, barbiturates, benzodiazepines,
                        cannabis, cocaine, hallucinogens, methaqualone, and
                        opioids. The exact cause of substance abuse is not
                        clear, but there are two predominant theories: either a
                        genetic predisposition or a habit learned from others,
                        which, if addiction develops, manifests itself as a
                        chronic debilitating disease."
            />
        ),
    },
    {
        children: (
            <Data
                heading="Testing for Substance Abuse"
                paragraph={
                    <Box>
                        The first step to dealing with substance abuse is
                        establishing a benchmark for yourself, a loved one, or
                        an opposing party in a case. Toolkit.law offers alcohol
                        and substance abuse testing via blood, hair follicle,
                        and urine testing for personal, professional, and legal
                        situations that preserve the chain of custody for
                        subsequent use in legal proceedings. For more
                        information, visit the Toolkit.law{' '}
                        <Link
                            href={`${linkUrl}/tools/tester`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Tester page
                        </Link>
                        .
                    </Box>
                }
            />
        ),
    },
];

const paralegalEmailData = {
    to: 'eric@paralegal.edu',
    subject: 'Paralegal.edu Inquiry Generated by Toolkit.law',
    body: `I would like to inquire regarding the legal professional training opportunities offered by Paralegal.edu. This email was generated by Toolkit.law.\n\nName: {{name}}\nPhone: {{phone}}\nEmail: {{email}}\nDiscount Code: Toolkit.lawn\n\nPlease advise how you can help me properly evaluate learning opportunities with Paralegal.edu as well as the financial considerations.\n\n`,
};

const parentingEmailData = {
    to: 'coparentingintothefuture@gmail.com ',
    subject: 'Co-Parenting 6-Hour Course Inquiry Generated by Toolkit.law',
    body: `I would like to inquire regarding the Co-Parenting Into The Future 6-hour course and in which jurisdictions it is accepted. This email was generated by Toolkit.law.\n\nName: {{name}}\nPhone: {{phone}}\nEmail: {{email}}\n\nProvince/State:{{province}}\nCoumtry:{{country}}\nDiscount Code: Toolkit.law\n\nPlease advise how you can help me properly evaluate this opportunity as well as the financial considerations.\n\n`,
};

const weightlossEmailData = {
    to: 'alvarez@endobariatric.com',
    subject: 'Endo Bariatric Surgery Inquiry, Generated by Toolkit.law',
    body: `I would like to inquire regarding Dr. Guillermo Alvarez’s gastric bypass sleeve surgery. I saw your information on Toolkit.law, along with the personal testimonial by Toolkit.law founder Tom King. Here is my information:\n\nName: {{name}}\nPhone: {{phone}}\nEmail: {{email}}\nMy Weight: ?\nMy Body Fat Percentage: ?\nDiscount Code: Toolkit.law\n\nPlease advise how you can help me properly evaluate this medical opportunity as part of permanent weight loss and life transformation solution.\n\n`,
};

export const paralegalDetails = [
    {
        children: (
            <Data
                heading="What is a Paralegal?"
                paragraph="A paralegal, also known as a legal assistant or paralegal specialist, is a legal professional who performs tasks that require knowledge of legal concepts but not the full expertise of a lawyer with an admission to practice law."
            />
        ),
    },
    {
        children: (
            <Data
                heading="Great Preparation for Law School"
                paragraph="In addition to being an important profession in the legal industry, being a paralegal is a great preparation for law school and becoming an attorney."
            />
        ),
    },
    {
        children: (
            <Data
                heading="Paralegal.EDU"
                emailData={paralegalEmailData}
                paragraph={
                    <Box>
                        The largest trainer of paralegals in North America is
                        Paralegal.EDU, based in Houston. Paralegal.EDU offers
                        Paralegal Certificates, Paralegal Associate Degrees, and
                        a Bachelor of Arts in Law and Business Degree. For more
                        information, email{' '}
                        <Link href="mailto:">Paralegal.edu</Link>.
                    </Box>
                }
            />
        ),
    },
];

export const parentingDetails = [
    {
        children: (
            <Data
                heading="What is Co-Parenting?"
                paragraph="Co-parenting classes, or co-parenting coaching, help separated or divorced parents learn how to communicate and cooperate more effectively as they raise their children together. Co-parenting, or shared parenting, is when both parents work together to raise their children, even after a romantic relationship ends."
            />
        ),
    },
    {
        children: (
            <Data
                heading="What is CITF?"
                emailData={parentingEmailData}
                paragraph={
                    <Box>
                        Co-parenting Into the Future (CITF) is North America's
                        leading co-parenting course, offering a 6-hour online
                        course that includes helping parties understand how to
                        behave in mediation and in court hearings. For more
                        information, <Link href="mailto:">email CPIF.</Link>
                    </Box>
                }
            />
        ),
    },
];

export const weightlossDetails = [
    {
        children: (
            <Data
                heading="What is Obesity?"
                paragraph={
                    <Box>
                        Overweight and{' '}
                        <Link
                            href="https://en.wikipedia.org/wiki/Obesity"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            obesity
                        </Link>{' '}
                        are defined as abnormal or excessive fat accumulation
                        that presents a risk to health. A body mass index (BMI)
                        over 25 is considered overweight, and over 30 is obese.
                    </Box>
                }
            />
        ),
    },
    {
        children: (
            <Data
                heading="How Does Obesity Affect the Body?"
                paragraph="Obesity makes you more likely to have high blood pressure and unhealthy cholesterol levels, which are risk factors for heart disease and strokes. It can also affect the way the body uses insulin to control blood sugar levels, raising the risk of insulin resistance and type 2 diabetes."
            />
        ),
    },
    {
        children: (
            <Data
                heading="How Do You Calculate Obesity?"
                paragraph={
                    <Box>
                        Toolkit.law offers a number of calculators on our{' '}
                        <Link
                            href="https://www.toolkit.law/numbers/health"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Health Calculators page
                        </Link>{' '}
                        and{' '}
                        <Link
                            href="https://www.toolkit.law/numbers/medical"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Medical Calculators page
                        </Link>
                        .
                    </Box>
                }
            />
        ),
    },
    {
        children: (
            <Data
                heading="The Permanent Cure to Obesity"
                paragraph={
                    <Box>
                        <Link
                            href="https://www.instagram.com/gmoalvarez/?hl=en"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Dr. Guillermo Alvarez
                        </Link>{' '}
                        is the world-renowned bariatric{' '}
                        <Link
                            href="https://www.endobariatric.com/gastric-sleeve/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            gastric sleeve
                        </Link>{' '}
                        surgeon in North America. Located at 3 hours from San
                        Antonio, Texas in Piedras Negras, Mexico, Dr. Alvarez
                        has completed over 22,000 of these surgeries at his own
                        dedicated{' '}
                        <Link
                            href="https://www.endohospital.mx/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Endohospital complex
                        </Link>
                        .
                    </Box>
                }
            />
        ),
    },
    {
        children: (
            <Data
                emailData={weightlossEmailData}
                paragraph={
                    <Box>
                        Toolkit.law founder Tom King is proud to have this
                        life-changing procedure as a patient of Dr. Guillermo
                        Alvarez in 2023. For more information,{' '}
                        <Link href="mailto:">
                            click here to generate your email inquiry
                        </Link>{' '}
                        to learn more about this life-changing, and for some
                        lifesaving, opportunity.
                    </Box>
                }
            />
        ),
    },
];

const technologyList = [
    {
        name: 'Google Workspace Certification',
        url: 'https://cloud.google.com/learn/training/workspace',
    },
    {
        name: 'Microsoft 365 Certification',
        url: 'https://www.udemy.com/topic/microsoft-office-365/',
    },
    {
        name: 'Toolkit.law Certified User Program',
        url: 'https://www.toolkit.law/help?accordion=certified-user-program',
    },
    {
        name: 'Udemy Digital Marketing Certificates ',
        url: 'https://www.udemy.com/courses/marketing/digital-marketing/',
    },
    {
        name: 'Udemy Social Media Marketing Certificate',
        url: 'https://www.udemy.com/courses/marketing/social-media-marketing/',
    },
];
export const technologyDetail = [
    {
        children: (
            <Data paragraph="Legal professionals benefit from technology training. Training programs that offer certification benefit your career. We suggest the following certified training programs to enhance a modern legal professional’s career. " />
        ),
    },
    {
        children: <Data paragraph={<ListItems items={technologyList} />} />,
    },
];
const suiteData = [
    {
        paragraph: `Toolkit.law features over 1,500+ legal tools, calculators &
                utilities. We have organized these into the following, optimized
                “suites” around which to streamline your modern legal practice
                and modern lifestyle.`,
        name: 'Creative Suite',
        link: '/suites/creative',
        features:
            'includes Audio Editor, Charter, Converter, Graphics Calculators, Imager, PDF Editor, Presenter, Publisher, QR Coder, Video Editor, and Viewer. It replaces Adobe Creative Suite, saving you $450/year.',
        imgSrc: 'https://admin.toolkit.law/storage/images/1727527236.svg',
    },
    {
        name: 'Documents Suite',
        link: '/suites/documents',
        features:
            'includes an Assets organizer, Bates numbering, Converter, Filer, Legal Calculators, a Liabilities organizer, Lifecycle event organizer, Notetaker, PDF Editor, Scanner, Signer, and Writer. It replaces MyCase, Clio, Docusign, and Adobe Acrobat Pro, saving $350/year.',
        imgSrc: '',
    },
    {
        name: 'Event Planning Suite',
        link: '/suites/event-planning',
        features:
            'includes Basic and Business Calculators, Budgeter, Calendar, Charter, Eventer, Marketing, Panner, and Presenter for your event planning and legal conference planning needs.',
        imgSrc: '',
    },
    {
        name: 'Family Law Suite',
        link: '/suites/family-law',
        features:
            'features an Assets organizer, Financial Calculators, Hearing manager, Homemaker, Improver, Income Calculators, Legal Calculators, a Liabilities organizer, Litigator, Matter tracking of client work, Mediator, Monetizer, Real Estate Calculators, Taxer, and Vehicular for organizing vehicle-related assets and liabilities.',
        imgSrc: '',
    },
    {
        name: 'Financial Suite',
        link: '/suites/financial-suite',
        features:
            'includes Budgeter, Homemaker, Invoicer, Monetizer, Payor payment platform, and Taxer. In addition, it includes 150+ Basic, Business, Finance, Income, Interest, Investment, and Percentage calculators.',
        imgSrc: '',
    },
    {
        name: 'Law School Suite',
        link: '/suites/law-school',
        features:
            'is a student and instructor’s modern collection to optimize classroom, homework, study group, exam preparation, and career preparation with our Career, Chatter, Improver, Legal Calculators, Monetizer, Notetaker, Organizer, School grade calculators, Semester task management tool, and Writer.',
        imgSrc: '',
    },
    {
        name: 'Life Suite',
        link: '/suites/life',
        features:
            'includes Caller, Career, Cooking, Doctor, Health, Improver, Income Calculators, Lifecycle planner, Military calculators, Payor platform, Retire, Romancer, Shopping organizer, and Taxer tax preparation organizer for the modern legal professional.',
        imgSrc: '',
    },
    {
        name: 'Litigation Suite',
        link: '/suites/litigation',
        features:
            'includes Bates Numbering, Discover, Docketer, Filer, Hearing Manager, Intaker, Invoicer, Juror for modern jury selection, Legal Calculators, Litigator, Matter for client work tracking, Mediator, PDF Editor, Researcher, Signer, and legal Time Calculators. Replaces JuryBox, Lit Software & Bates Numbering, saving you $1K/year.',
        imgSrc: '',
    },
    {
        name: 'Marketing Suite',
        link: '/suites/marketing',
        features:
            'includes Charter, Communicator, Graphics Calculators, Imager, Marketer, Newsletter, QR Coder, Socializer, and your own User Webstore. Replaces website, QR Code & social content software, saving $400/year.',
        imgSrc: '',
    },
    {
        name: 'Mediation Suite',
        link: '/suites/mediation',
        features:
            'includes an Assets organizer, Basic and Business Calculators, Conferencer, Filer, Income Calculators, Legal Calculators, a Liabilities organizer, Mediator ADR manager, our Payor payment platform, Real Estate Calculators, Signer legal document signing, and Writer. Your complete mediation preparation & participation suite, saving $300/year.',
        imgSrc: '',
    },
    {
        name: 'Office Suite',
        link: '/suites/office',
        features:
            'includes Calendar, Charter, Chatter, Communicator, Contactor, Converter, Notetaker, Organizer, Presenter, Spreadsheeter, and Writer. This replaces your office productivity suite needs for the modern legal professional, saving you $150/year.',
        imgSrc: '',
    },
    {
        name: 'Operations Suite',
        link: '/suites/operations',
        features:
            'includes Basic and Business Calculators, Budgeter, Caller, Chatter, Driver, HR Manager, IT Manager, our Payor platform, Planner, Protector, Sales calculators, Technology calculators, Transportation calculators, Tripper travel organizer, and Vehicular asset and liabilities organizer. Replaces LawPay, Slack and more, saving you $350/year.',
        imgSrc: '',
    },
    {
        name: 'Travel Suite',
        link: '/suites/travel',
        features:
            'includes our Budgeter, Driver, Planner, Time Calculators, Transportation calculators, Traveler, Tripper, Vehicular, and Weather information organizer.',
        imgSrc: '',
    },
];

export const modernPracticeDetails = suiteData.map((suite) => ({
    children: (
        <Box>
            <Typography>{suite.paragraph}</Typography>
            <Data
                heading={suite.name}
                paragraph={
                    <Box>
                        The Toolkit.law{' '}
                        <ExternalLink
                            href={suite.link}
                            text={suite.name}
                            newTab={false}
                        />{' '}
                        {suite.features}
                    </Box>
                }
            />
            {/* <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    component="img"
                    src={suite.imgSrc}
                    alt={`${suite.name} banner`}
                    width="200"
                    height="50"
                />
            </Container> */}
        </Box>
    ),
}));
